// Libraries
import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

// Style
import "./style.css";

function ChartTable({ item }) {

  const [filterList, setFilterlist] = useState([]);
  const [order, setOrder] = useState('ascend');

  const columns = []
  columns.push({
    title: '#',
    dataIndex: 'Sr',
    key: 'Sr',
    width: 50,
    fixed: true,

  },)
  filterList?.forEach((element, index) => {
    element.Sr = index + 1
  });
  const sorting = (column) => {
    if (order == "descend") {
      let filter = filterList.sort((a, b) => a[column.key] > b[column.key] ? -1 : 1)
      setFilterlist(filter)
    }
    else if (order == "ascend") {
      let filter = filterList.sort((a, b) => a[column.key] < b[column.key] ? -1 : 1)
      setFilterlist(filter)
    }
    else {
      // let filter = filterList.sort((a, b) => a[column.key] < b[column.key] ? -1 : 1)
      setFilterlist(item?.table_data_objects)
    }

  }

  item?.table_header?.map((obj, index) => {
    columns.push({
      title: obj.value,
      dataIndex: obj.key,
      key: obj.key,
      width: index == 0 ? 150 : 150,
      fixed: index == 0,
      sorter: true,
      showSorterTooltip: false,
      sorter: (a, b) => index == 0 ?  b[obj.key] > a[obj.key] ? -1 : 1 :  b[obj.key] - a[obj.key],
      sortDirections: ['ascend', 'descend', 'ascend'],

      render(text, record) {

        return {
          props: {
            style: { background: index != 0 ? `rgb(48 105 10 / ` + (text / 10) + `)` : '', color: '#000000a1' }
          },
          children: <div>{text}</div>
        };
      },
      // onHeaderCell: (column) => {
      //   return {
      //     onClick: () => {
      //       setOrder(order === 'ascend' ? 'descend' : 'ascend');
      //       console.log('onClick', column);
      //       sorting(column)
      //     }
      //   };
      // }
    },)
  });

  useEffect(() => {
  }, [order]);


  const searccValue = (values) => {
    let filter = item?.table_data_objects
    console.log(filterList)
    filter = filter.filter(x => x.STRAINSPHENOTYPES.toLowerCase().includes(values.target.value.toLowerCase()))
    filter?.forEach((element, index) => {
      element.Sr = index + 1
    });

    setFilterlist(filter)


  }
  useEffect(() => {
    if (item && filterList.length == 0)
      setFilterlist(item?.table_data_objects)
  }, [item, filterList]);

  useEffect(() => {
  }, [order]);
  return (
    <div className="chart-table" >
      <div className="serach">
        <label style={{ marginRight: '10px' }}> Search Strain:</label>
        <Input className="serach" onKeyUp={searccValue}></Input>
      </div>
      <Table
        columns={columns}
        loading={!item}
        pagination={false}
        width="100%"
        // dataSource={filterItem}
        dataSource={filterList}
        scroll={{
          x: 1000,
          y: 500,
        }}
      />
    </div>
  );
}

export default ChartTable;
