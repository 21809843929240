import interceptor from "../../../shared/interceptor"
import actionTypes from "./user.action.type"


const userActions = {
    signIn: (data) => {
        return (dispatch) => {
            dispatch({ type: actionTypes.signIn, payload: data })
        }
    },

    clearChache() {
        return (dispatch) => {
            dispatch({ type: actionTypes.clearChahce, payload: null })
        }
    },

    getUser(token) {
        return (dispatch) => {
            interceptor.axiosGet('get', 'users/', token).then((res) => {
                if (res.status === 200) {
                    dispatch({ type: actionTypes.users, payload: res.data })
                }
                else {
                    console.log("error getting user : ", res)
                }
            }).catch((err) => {
                return err
            })
        }
    },

    getUserCredits(token, id) {

        return interceptor.axiosGet('get', 'get-credits/', token).then((res) => {
            if (res.status === 200) {
                return res.data.data
                // dispatch({ type: actionTypes.userCredits, payload: res.data.data })
            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })

    },

    getAdminOrders(token, type) {
        return (dispatch) => {
            interceptor.axiosGet('get', `admin/orders/?status=${type}`, token).then((res) => {
                if (res.status === 200) {
                    if (type === "PROCESSING") { dispatch({ type: actionTypes.orderProcessing, payload: res.data }) }
                    else if (type === "PENDING") { dispatch({ type: actionTypes.ordersPending, payload: res.data }) }
                    else {
                        dispatch({ type: actionTypes.ordersDelivered, payload: res.data })
                    }
                }
                else {
                    console.log("error getting user : ", res)
                }
            }).catch((err) => {
                return err
            })
        }
    },

    addUser(token, model, url) {
        return interceptor.axiosPost('Post', url, model, token).then((res) => {
            if (res.status === 200) {
                this.getUser()
                return true

            }
            else {
                console.log("error adding user : ", res)
            }
        }).catch((err) => {
            return err
        })
    },

    getUserOrders(token, id) {
        return interceptor.axiosGet('GET', 'order/detail/?id=' + id, token).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                console.log("error getting order detail : ", res)
            }
        }).catch((err) => {
            return err
        })
    },

    getVisualOrderDetail(token, id) {
        return interceptor.axiosGet('GET', 'get-visual-order-details/?id=' + id, token).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                console.log("error getting order detail : ", res)
            }
        }).catch((err) => {
            return err
        })
    },


    getSubVisualDetail(token, id, lineNo) {
        return interceptor.axiosGet('GET', 'get-sub-visual-details/?id=' + id + '&line_no=' + lineNo, token).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                console.log("error getting order detail : ", res)
            }
        }).catch((err) => {
            return err
        })
    },

    getUserOrderList(token) {
        return (dispatch) => {
            interceptor.axiosGet('GET', 'user/orders/', token).then((res) => {
                if (res.status === 200) {
                    dispatch({ type: actionTypes.userOrderList, payload: res.data })
                }
                else {
                    console.log("error getting order detail : ", res)
                }
            }).catch((err) => {
                return err
            })
        }
    },

    getOrdersListByOrderId(token, id) {
        return interceptor.axiosGetDownload('GET', 'admin/order/download-order-files/?id=' + id, token).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                console.log("error getting While downloading : ", res)
            }
        }).catch((err) => {
            return err
        })

    },

    getUserDetail(token, id) {
        return interceptor.axiosGet('GET', 'users/detail?id=' + id, token).then((res) => {
            if (res.status === 200) {
                return res.data.data
            }
            else {
                console.log("error getting While downloading : ", res)
            }
        }).catch((err) => {
            return err
        })

    },

    getprofile(token, id) {
        return interceptor.axiosGet('GET', 'get-profile/', token).then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                console.log("error getting While downloading : ", res)
            }
        }).catch((err) => {
            return err
        })

    },

    updateUserCredit(token, data) {
        return interceptor.axiosPatch('Patch', 'add-credits-to-user/', data, token).then((res) => {
            if (res.status === 200) {
                return res.data.data
            }
            else {
                console.log("error getting While downloading : ", res)
            }
        }).catch((err) => {
            return err
        })

    },
}


export default userActions
