import React, { useEffect, useState } from "react";

// Components
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";
import { useDispatch } from "react-redux";

const Layout = ({ children, title }) => {
  const [isActive, setIsActive] = useState(false);
  const { token, userID } = useToken()
  const dispatch = useDispatch()
  const handleClick = () => {
    setIsActive(!isActive);
  };

  // useEffect(() => {
  //   userActions.getUserDetail(token, userID).then(res => {
  //     dispatch({ type: actionTypes.user, payload: res })
  //   })
  // })
  return (
    <>
      <Sidebar click={handleClick} state={isActive} />
      <div className={`main-content ${isActive ? 'active' : ''}`}>
        <Header title={title} />
        <main className="main">
          {children}
        </main>
      </div>
    </>
  );
};

export default Layout;
