// Libraries
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { Row, Col, Typography, Spin } from "antd";

// Style
import "./style.css";

const { Title, Text } = Typography;
function DonutChart({ item, title, id, loading, subTitle }) {
  const [state, setState] = useState([]);

  useEffect(() => {
    setState({
      series: item?.value_list || [],
      options: {
        labels: item?.name_list || [],
        colors: [function ({ value, seriesIndex, w }) {
          return [" - ", value,]
        }],
        fill: {
          colors: item?.color_list || []
        },
        legend: {
          position: 'right',
          formatter: function (val, opts) {
            // return [opts.w.globals.series[opts.seriesIndex], " - ", val,]
            return `<div class="custom-marker"> 
            <div class="custom-marker-number" style="background:${item?.color_list[opts.seriesIndex]}">${opts.w.globals.series[opts.seriesIndex].toFixed(2)}</div>
            <div class="custom-marker-value">${val}</div>
          </div>`
          },
          offsetY: -15,
          offsetX: -10,
          onItemHover: {
            highlightDataSeries: false
          },
        },
        chart: {
          type: "donut",
          height: "300px",
        },
        dataLabels: {
          enabled: true,
          distributed: true,
          style: {
            fontSize: '15px',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 0
            },
            donut: {
              size: "40%"
            }
          }
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
                height: "500px",
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '13px',
                }
              },

              legend: {
                formatter: function (val, opts) {
                  // return [opts.w.globals.series[opts.seriesIndex], " - ", val,]
                  return `<div class="custom-marker " style="overflow:hidden"> 
                  <div class="custom-marker-number" style="background:${item?.color_list[opts.seriesIndex]}">${opts.w.globals.series[opts.seriesIndex].toFixed(2)}</div>
                  <div class="custom-marker-value">${val}</div>
                </div>`
                },
                offsetY: 0,
                offsetX: -30,
                onItemHover: {
                  highlightDataSeries: false
                },
              },
            },
          },
        ],
      },
    });
  }, [item])
  return (
    <>
      <div className="donut-chart-text">
        <Text></Text>
        <Text>{title}</Text>
        <Text>{subTitle}</Text>
      </div>
      {!loading ?
        <div id={id} className="donat-chart">
          {item && <ReactApexChart
            options={state?.options}
            series={state?.series}
            type="donut"
            height={state?.options.chart.height}
          />}
        </div>
        :
        <Spin></Spin>
      }
    </>
  );
}

export default DonutChart;
