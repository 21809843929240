// Libraries
import React, { useEffect, useState } from "react";
import { Table, Typography, Tag, Select, Input } from "antd";
import { Link } from "react-router-dom";

// Componenets
import Layout from "../../../components/layout/Layout";
import MyButton from "../../../components/mybutton/MyButton";

// Style
import "./index.css";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";

const { Title, Text } = Typography;
const { Search } = Input;
const onSearch = (value) => console.log(value);
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
const status = [{ id: 'RECREATIONAL_BASIC', name: 'Adult Use (Basic)' },
{ id: 'MEDICAL_BASIC', name: 'Therapeutic (Basic)' },
{ id: 'MEDICAL_AND_RECREATIONAL_BASIC', name: 'Therapeutic & Adult Use (Basic)' },
{ id: 'MEDICAL_AND_RECREATIONAL_ADVANCED', name: 'Therapeutic & Adult Use (Advanced)' },
{ id: 'MEDICAL_AND_RECREATIONAL_COMPLETE', name: 'Therapeutic & Adult Use (Complete)' },
{ id: 'package_6', name: '6 Adult Use' },
{ id: 'package_7', name: '6 Therapeutic' },
{ id: 'package_8', name: 'Combined Analysis' },
]
function ViewOrders() {
  const userOrderList = useSelector((state) => state.userReducer.userOrderList)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { token } = useToken()
  const columns = [
    {
      title: "Order Title",
      dataIndex: "name",
      key: "name",
      width: 25,
      fixed: "left",
      render: (tags, row) => (
        <span style={{ float: 'left' }}>{row.name}</span>
      )
    },
    {
      title: "View Results",
      dataIndex: "viewresults",
      key: "viewresults",
      width: 40,
      render: (tags, row) => (
        <span>
          {Array.isArray([row.status]) &&
            [row.status].map((tag) => {
              if (tag !== "COMPLETED") {
                return (
                  <Link to="#" style={{ background: "#F1F1F5", color: "#696974" }}>
                    Processing
                  </Link>
                )
              } else {
                return (
                  <Link
                    to={`/view-csv/${row.id}`}
                    style={{ background: "#609BE5", color: "#fff", paddingLeft: "22px", paddingRight: "22px" }}
                  >
                    Open
                  </Link>
                )
              }
              // return (
              //   <Tag color={color} key={tag}>
              //     {tag.toUpperCase()}
              //   </Tag>
              // );
            })}
        </span>
      ),
    },
    {
      title: "Order #",
      dataIndex: "id",
      key: "id",
      width: 20,
    },
    {
      title: "Date Ordered",
      dataIndex: "created_at",
      key: "created_at",
      width: 30,
      render: (tags, item) => (
        new Date(item.created_at).toLocaleString()
      )

    },
    {
      title: "Effect Analysis Depth",
      dataIndex: "package_selected",
      key: "package_selected",
      width: 30,
      render: (tags, item) => (
        status.find(x => x.id == item.package_selected)?.name
      )
    },
    // {
    //   title: "Payment Status",
    //   key: "payment_status",
    //   dataIndex: "payment_status",
    //   width: 150,
    //   render: (tags) => (
    //     <span>
    //       {Array.isArray([tags]) &&
    //         [tags].map((tag) => {
    //           let color = tag === "Paid" ? "green" : "red";
    //           return (
    //             <Tag color={color} key={tag}>
    //               {tag.toUpperCase()}
    //             </Tag>
    //           );
    //         })}
    //     </span>
    //   ),
    // },
    {
      title: "Order Status",
      key: "status",
      dataIndex: "status",
      width: 35,
      render: (tags) => (
        <span>
          {Array.isArray([tags]) &&
            [tags].map((tag) => {
              let color;
              let backgroundColor;

              if (tag === "DELIVERED") {
                color = "#389e0d";
                backgroundColor = "#f6ffed";
              } else {
                color = "#696974";
                backgroundColor = "#F1F1F5";
              }

              return (
                <Tag
                  style={{
                    color,
                    backgroundColor,
                    border: "0",
                    padding: "3px 15px",
                  }}
                  key={tag}
                >
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
        </span>
      ),
    },
  ];

  const table = [
    {
      key: "1",
      ordertitle: "Summer Harvest #2",
      viewresults: (
        <Link to="#" style={{ background: "#F1F1F5", color: "#696974" }}>
          Processing
        </Link>
      ),
      dateordered: "Jun 21, 2022",
      effectanalysisdepth: "10 Adult Use",
      paymentstatus: ["Paid"],
      orderstatus: ["Delivered"],
    },
    {
      key: "2",
      ordertitle: "Summer Harvest #2",
      viewresults: (
        <Link
          to="/view-csv/"
          style={{ background: "#609BE5", color: "#fff", paddingLeft: "22px", paddingRight: "22px" }}
        >
          Open
        </Link>
      ),
      dateordered: "Jun 21, 2022",
      effectanalysisdepth: "10 Adult Use",
      paymentstatus: ["Due"],
      orderstatus: ["Processing"],
    },
  ];

  useEffect(() => {
    setLoading(false)
    if (userOrderList?.length === 0) {
      setLoading(true)
      dispatch(userActions.getUserOrderList(token))
    }
  })
  return (
    <Layout title="View Order Results">
      <div className="view-orders">
        <div className="table-selects">
          <Title level={3}>Orders</Title>
          {/* <div className="show-select">
            <Text>Show:</Text>
            <Select
              defaultValue="0"
              onChange={handleChange}
              options={[
                { value: "5", label: "5" },
                { value: "10", label: "10" },
                { value: "15", label: "15" },
              ]}
            />
          </div> */}
          {/* <Search
            placeholder="Search Titles..."
            onSearch={onSearch}
            allowClear
            style={{
              width: 300,
              marginLeft: "30px",
              position: "relative",
            }}
          /> */}
        </div>
        <div className="sort-selects">
          <Text>Sort by:</Text>
          <Select
            defaultValue="default"
            onChange={handleChange}
            options={[
              { value: "newest", label: "Newest" },
              { value: "oldest", label: "Oldest" },
            ]}
          />
        </div>
      </div>
      <div className="view-orders-table">
        <Table
          columns={columns}
          dataSource={userOrderList?.orders}
          scroll={{
            x: 567,
          }}
          loading={loading}
        />
      </div>
    </Layout>
  );
}

export default ViewOrders;
