import axios from 'axios'
export const baseUrl = process.env.REACT_APP_BASE_URL
console.log(baseUrl)
const interceptor = {
    axiosPost: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            //data: new URLSearchParams(data),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosLogin: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosPut: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            // headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosPatch: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            // headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosGet: (method, urlPath, token, data) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosGetWithParams: (method, urlPath, token, data) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
            params: data,
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosDelete: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },

    // Post Chunks Upload Porfile Picture
    axiosUploadProfilePic: (method, urlPath, data, token) => {
        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(data),
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },

    axiosGetDownload: (method, urlPath, token, data) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob'
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },
}

export default interceptor
