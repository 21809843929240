import React from "react";
import { Menu, Typography, Image } from "antd";

// Images
import logo from "../../assets/images/logotm.png";
import { Link, useNavigate } from "react-router-dom";

// Components
import {
  Dashboard,
  Logout,
  Management,
  Results,
  Settings,
  Toggle,
} from "../../icons/Icons";

// Style
import "./style.css";
import useToken from "../../hooks/useToken";
import { useDispatch } from "react-redux";
import userActions from "../../redux-toolkit/actions/user/user.action";

const { Text } = Typography;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export default function Sidebar({ click, state }) {
  const { removeToken, isAdmin } = useToken();
  const dispatch = useDispatch()

  const naviagte = useNavigate();
  const adminitems = [

    getItem(
      "Admin Dashboard",
      "1",
      <Link to="/main-page" className="admin-item">
        <span>
          <Dashboard color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),

    getItem(
      "User Management",
      "2",
      <Link to="/user-management" className="user-item">
        <span>
          <Management color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),

    getItem(
      "Account",
      "3",
      <Link to="/profile">
        <span>
          <Settings color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),
    getItem(
      "Logout",
      "4",
      <a
        onClick={() => {
          removeToken();
          dispatch(userActions.clearChache())
          naviagte("/login");
        }}
      >
        <span>
          <Logout color="rgb(147, 147, 147)" />
        </span>
      </a>
    ),
  ];

  const cliclbar = () => {
    click()
    let id = document.getElementById("activebar")
    if (id && id.classList.contains('activebar')) {
      document.getElementById("activebarClick").click();
    }
  }


  const userItems = [
    getItem(
      "New Analysis",
      "3",
      <Link to="/new-analysis-order" className="analysis-item">
        <span>
          <Dashboard color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),

    getItem(
      "View Results",
      "4",
      <Link to="/view-orders" className="results-item">
        <span>
          <Results color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),

    getItem(
      "Account",
      "5",
      <Link to="/profile" className="account-item">
        <span>
          <Settings color="rgb(147, 147, 147)" />
        </span>
      </Link>
    ),

    getItem(
      "Logout",
      "6",
      <a
        className="logout-item"
        onClick={() => {
          removeToken();

          naviagte("/login");

        }}
      >
        <span>
          <Logout color="rgb(147, 147, 147)" />
        </span>
      </a>
    ),
  ];
  return (
    <div id="sidebar" className={`sidebar ${state ? "active" : ""}`}>
      <div className="toggle-menu" id="sidebarClick" onClick={cliclbar} >
        <Toggle color="rgb(21, 21, 23)" />
      </div>
      <aside>
        <Link href="/" title="">
          <Image preview={false} src={logo} alt="Logo" />
        </Link>
        <div className="side-menu">
          <Text type="secondary">Menu</Text>
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            theme="light"
            items={isAdmin ? adminitems : userItems}
          />
        </div>
      </aside>
    </div>
  );
}
