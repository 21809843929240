// Libraries
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Typography, Checkbox, Button } from "antd";

// Styles
import "./styles.css";
import MyButton from "../../mybutton/MyButton";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import { useParams } from "react-router-dom";

const { Title } = Typography;
function AddNewUser({ user, showCheckbox, form, onUpdateCheck }) {
  const { id } = useParams();
  const [check, setCheck] = useState(user?.account_type == "SUBSCRIPTION" ? true : false)

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheck(e.target.checked)
    onUpdateCheck(e.target.checked)
    form.setFieldsValue({
      "account_type": e.target.checked ? "SUBSCRIPTION" : 'REGULAR',
    })
  };


  useEffect(() => {
    if (user) {
      setCheck(user?.account_type == "SUBSCRIPTION" ? true : false);
    }
  }, [user])



  return (
    <div className="add-new-user">
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={24} md={16} lg={12} xl={8}>
          <Title level={5}>Add New User</Title>
          <Form.Item label="Comapny Name"
            name="company"
            rules={[
              {
                required: true,
                message: 'Please Enter Company Name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please Enter Email',
              },
            ]}>
            <Input />
          </Form.Item>
          {/* <Form.Item label="Password"
            name="password"
            rules={[
              {
                required: id ? false : true,
                message: 'Please Enter Password',
              },
            ]}>
            <Input />
          </Form.Item> */}
          <Form.Item label="Phone"
            name="phone_number"
            rules={[
              {
                required: true,
                message: 'Please Enter PhoneNumber',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item label="Full Name"
            name="full_name"
            rules={[
              {
                required: true,
                message: 'Please Enter Full Name',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please Enter Address',
              },
            ]}>
            <Input />
          </Form.Item>
          {showCheckbox ? (
            <Form.Item
              name="account_type"

            >
              <Checkbox onChange={onChange} checked={check}>
                Subscription Services Only
              </Checkbox>
            </Form.Item>
          ) : null}
          <div className="add-user-btn">
            {/* <Button
              className="my-button"
              style={{
                backgroundColor: "#609BE5",
                color: "#fff",
                width: "initial",
                display: "inline-block",
              }}
              htmlType="submit"
            >
              {showCheckbox ? "Save" : "Add User (Standard Pricing)"}
            </Button> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AddNewUser;
