// Libraries
import React from "react";
import { Link } from "react-router-dom";

function MyButton(props) {
  return (
    <Link
      className="my-button"
      style={{ backgroundColor: props.background, color: props.color }}
      to={props.link}
    >
      {props.text}
    </Link>
  );
}

export default MyButton;
