import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';
import NewAnalysisOrder from '../pages/user/newanalysisorder/NewAnalysisOrder';

const UserRoutes = () => {

    const { token, isAdmin, accountType } = useToken();
    return (isAdmin) ? <Outlet /> : <Navigate to="/new-analysis-order" />
}

export default UserRoutes;