// Libraries
import React, { useState } from "react";
import { Typography, Image, Checkbox, Form, Input, Button, Alert } from "antd";

// Components
import authLogo from "../../assets/images/logotm.png";
import MyButton from "../../components/mybutton/MyButton";
import { useShareDispatch } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./index.css";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../apis/auth";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";

const { Title, Paragraph, Text } = Typography;

function ResetPassword() {
  const naviagte = useNavigate();
  const [isSigning, setIsSigning] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saveLoginUser } = useToken();
  const [message, setMessage] = useState('')
  const location = useLocation();
  console.log(location.pathname);
  // const searchParams = useSearchParams();
  // const code = searchParams.get('code');

  const code = new URLSearchParams(window.location.search).get(
    "code"
  );
  console.log(code)

  const onFinish = async (values) => {
    values.code = code
    console.log("Success:", values);
    if (values.password == values.cpassword) {
      setIsSigning(true);
      resetPassword(values)
        .then(async (res) => {
          setIsSigning(false);
          if (res.status == 200) {
            naviagte("/login");
          }
          else {
            setMessage(res.message)
          }
        })
        .catch((error) => {
          setIsSigning(false);
        });
    }
    else {
      setMessage("Password and Confirm Password are incorrect")
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <section className="login">
      <div className="auth-bg">
        <div className="auth">
          <span className="auth-logo">
            <Image
              preview={false}
              src={authLogo}
              alt="Logo"
            />
          </span>
          <div className="login-form">
            <Title level={4}> {location.pathname == '/set-password' ? 'Set up your password' : 'Reset your password'} </Title>
            <Text level={4}>Back To <Link to="/login">Sign In</Link></Text>
            <Form
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="cpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your confirm password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  className="my-button"
                  style={{ backgroundColor: "#609BE5", color: "#fff" }}
                  loading={isSigning}
                  htmlType="submit"
                >
                  
                   {location.pathname == '/set-password' ? 'Set Password' : 'Reset'}
                </Button>
              </Form.Item>
              {message && <Alert type="error" message={message}></Alert>}
            </Form>
          </div>
        </div>
        {/* <div className="login-footer">
          <div className="footer-title">
            <Title level={3} style={{ color: '#fff', margin: '0', marginRight: '5px' }}> No Account?  </Title>
            <Title level={3} style={{ color: '#fff', margin: '0' }}> Sign Up In Seconds! </Title>
          </div>
          <div className="footerBtn">
            <Button
              className="signUpbtn"
            >
              <Link to="/register">  Sign up</Link>
            </Button>
            <Button
              className="loginUpbtn"
            >
              <Link to="/login"> Login</Link>
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default ResetPassword;
