// Libraries
import React, { useState } from "react";
import { Typography, Image, Checkbox, Form, Input, Button, Modal } from "antd";

// Components
import authLogo from "../../assets/images/logotm.png";
import MyButton from "../../components/mybutton/MyButton";
import { useShareDispatch } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../apis/auth";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";
import TermCondition from "../termsCondition/termCondition";

const { Title, Paragraph } = Typography;

function Login() {
  const naviagte = useNavigate();
  const [isSigning, setIsSigning] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saveLoginUser } = useToken();

  const onFinish = async (values) => {
    setIsSigning(true);
    console.log("Success:", values);
    loginUser(values)
      .then(async (res) => {
        setIsSigning(false);
        if (res.status == 200) {
          await saveLoginUser(res.data);
          await dispatch({ type: actionTypes.singIn, payload: res.data });
          naviagte("/");
          // if (res.data.user.is_admin) {
          //   naviagte("/main-page");
          // } else {
          //   naviagte("/new-analysis-order");

          // }
        } else {
          form.setFields([
            {
              name: "password",
              errors: [res.message],
            },
            {
              name: "email",
              errors: [res.message],
            },
          ]);
        }
      })
      .catch((error) => {
        setIsSigning(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="login" style={{overflow:'unset'}}>
      <div className="auth-bg">
        <div className="auth auth-model" style={{overflow:'unset'}}>
          <Modal
            centered
            width="auto"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="auth-model"
            cancelButtonProps={{ style: { display: 'none' } }}
          >

            <div className="modal-header" style={{ background: '#fff' }} >
              <div className="modal-logo">
                <span className="auth-logo">
                  <Image
                    preview={false}
                    src={authLogo}
                    alt="Logo"
                  />
                </span>
                <Title level={4} style={{ textAlign: "center" }}>Canalytics.ai Web App</Title>
                <Title level={4} style={{ textAlign: "center" }}>Terms of Service</Title>
              </div>
            </div>
            <div className="modal-body" style={{ background: '#fff' }}>
              <TermCondition></TermCondition>
            </div>
          </Modal>
          <span className="auth-logo" >
            <Image
              preview={false}
              src={authLogo}
              alt="Logo"
            />
          </span>
          <div className="login-form">
            <Title level={4}>Sign In</Title>
            <Form
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                  {
                    type: "email"
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Title level={5}>
                  <Link to="/forgot-password">Forgot Password</Link>
                </Title>
              </Form.Item>
              {/* <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please accept terms of service!",
                  },
                ]}
                name="remember" valuePropName="checked">
                <Checkbox>
                  I have read and agree to the{" "}
                  <a href="#" onClick={showModal}>Terms of Service</a>
                </Checkbox>
              </Form.Item> */}
              <Form.Item>
                <Button
                  className="my-button mt-4"
                  style={{ backgroundColor: "#609BE5", color: "#fff" }}
                  loading={isSigning}
                  htmlType="submit"
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
            {/* <Paragraph>
              Don't have an account ? <Link to="/register"> Click Here</Link> to Register.
            </Paragraph> */}
          </div>
        </div>
        {/* <div className="login-footer">
          <div className="footer-title">
            <Title level={3} style={{ color: '#fff', margin: '0',marginRight:'5px' }}> No Account?  </Title>
            <Title level={3} style={{ color: '#fff', margin: '0' }}> Sign Up In Seconds! </Title>
          </div>
          <div className="footerBtn">
            <Button
              className="signUpbtn"
            >
              <Link to="/register">  Sign up</Link>
            </Button>
            <Button
              className="loginUpbtn"
            >
              <Link to="/login"> Login</Link>
            </Button>
          </div>
        </div> */}
      </div>

    </section>
  );
}

export default Login;
