var PricingCardData = [
  {
    topTitle: "10",
    bottomTitle: "Rec",
    background: "#FBB703",
    color: "#fff",
    items: [{
      key: "recreational_basic_single",
      value: "1",
      custom: false
    },
    {
      key: "recreational_basic_single_custom",
      value: "",
      custom: true
    },
    {
      key: "recreational_basic_500",
      value: "50",
      custom: false
    },
    {
      key: "recreational_basic_500_custom",
      value: "",
      custom: true
    },
    {
      key: "recreational_basic_1000",
      value: "60",
      custom: false
    },
    {
      key: "recreational_basic_1000_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5",
    bottomTitle: "Medical",
    background: "#5FD029",
    color: "#fff",
    items: [{
      key: "medical_basic_single",
      value: "2",
      custom: false
    },
    {
      key: "medical_basic_single_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_basic_500",
      value: "55",
      custom: false
    },
    {
      key: "medical_basic_500_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_basic_1000",
      value: "65",
      custom: false
    },
    {
      key: "medical_basic_1000_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5 Med",
    bottomTitle: "10 Rec",
    background: "#57268D",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_basic_single",
      value: "3",
      custom: false
    },
    {
      key: "medical_and_recreational_basic_single_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_basic_500",
      value: "60",
      custom: false
    },
    {
      key: "medical_and_recreational_basic_500_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_basic_1000",
      value: "75",
      custom: false
    },
    {
      key: "medical_and_recreational_basic_1000_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "10 Med",
    bottomTitle: "10 Rec",
    background: "#1C69C2",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_advanced_single",
      value: "4",
      custom: false
    },
    {
      key: "medical_and_recreational_advanced_single_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_advanced_single_500",
      value: "65",
      custom: false
    },
    {
      key: "medical_and_recreational_advanced_single_500_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_advanced_500",
      value: "75",
      custom: false
    },
    {
      key: "medical_and_recreational_advanced_500_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "20 Med",
    bottomTitle: "10 Rec",
    background: "#BB1F89",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_complete_single",
      value: "5",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_single_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_complete_500",
      value: "70",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_500_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_complete_1000",
      value: "80",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_1000_custom",
      value: "",
      custom: true
    },
    ],
  },
];

export const PricingCardRegularData = [
  {
    topTitle: "10",
    bottomTitle: "Adult",
    background: "#FBB703",
    color: "#fff",
    items: [{
      key: "recreational_strains_10",
      value: "5",
      custom: false
    },
    {
      key: "recreational_strains_10_custom",
      value: "",
      custom: true
    },
    {
      key: "recreational_strains_25",
      value: "6",
      custom: false
    },
    {
      key: "recreational_strains_25_custom",
      value: "",
      custom: true
    },
    {
      key: "recreational_strains_50",
      value: "7",
      custom: false
    },
    {
      key: "recreational_strains_50_custom",
      value: "",
      custom: true
    },
    {
      key: "recreational_unlimited",
      value: "12",
      custom: false
    },
    {
      key: "recreational_unlimited_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5",
    bottomTitle: "Medical",
    background: "#5FD029",
    color: "#fff",
    items: [{
      key: "medical_strains_10",
      value: "6",
      custom: false
    },
    {
      key: "medical_strains_10_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_strains_25",
      value: "7",
      custom: false
    },
    {
      key: "medical_strains_25_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_strains_50",
      value: "8",
      custom: false
    },
    {
      key: "medical_strains_50_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_strains_unlimited",
      value: "13",
      custom: false
    },
    {
      key: "medical_strains_unlimited_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5 Med",
    bottomTitle: "10 Rec",
    background: "#57268D",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_strains_5_10",
      value: "7",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_5_10_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_strains_5_25",
      value: "8",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_5_25_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_strains_5_50",
      value: "9",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_5_50_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_5_unlimited",
      value: "14",
      custom: false
    },
    {
      key: "medical_and_recreational_5_unlimited_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "10 Med",
    bottomTitle: "10 Rec",
    background: "#1C69C2",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_strains_10",
      value: "8",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_10_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_strains_25",
      value: "9",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_25_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_strains_50",
      value: "10",
      custom: false
    },
    {
      key: "medical_and_recreational_strains_50_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_unlimited",
      value: "15",
      custom: false
    },
    {
      key: "medical_and_recreational_unlimited_custom",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "20 Med",
    bottomTitle: "10 Rec",
    background: "#BB1F89",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_complete_strains_10",
      value: "9",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_strains_10_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_complete_strains_25",
      value: "10",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_strains_25_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_complete_strains_50",
      value: "11",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_strains_50_custom",
      value: "",
      custom: true
    },
    {
      key: "medical_and_recreational_complete_unlimited",
      value: "16",
      custom: false
    },
    {
      key: "medical_and_recreational_complete_unlimited_custom",
      value: "",
      custom: true
    },
    ],
  },
];

export const ManuallyPricingCardData = [

  {
    topTitle: "6",
    bottomTitle: "Adult",
    background: "#ec7979",
    color: "#fff",
    items: [{
      key: "package_6",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "6",
    bottomTitle: "Therap",
    background: "#59cab9",
    color: "#fff",
    items: [{
      key: "package_7",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "6 Adult",
    bottomTitle: "6 Therap",
    background: "#b821fe",
    color: "#fff",
    items: [{
      key: "package_8",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "10",
    bottomTitle: "Adult",
    background: "#FBB703",
    color: "#fff",
    items: [{
      key: "recreational_basic_credits",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5",
    bottomTitle: "Therap",
    background: "#5FD029",
    color: "#fff",
    items: [{
      key: "medical_basic_credits",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "5 Therap",
    bottomTitle: "10 Adult",
    background: "#57268D",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_basic",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "10 Therap",
    bottomTitle: "10 Adult",
    background: "#1C69C2",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_advanced",
      value: "",
      custom: true
    },
    ],
  },
  {
    topTitle: "20 Therap",
    bottomTitle: "10 Adult",
    background: "#BB1F89",
    color: "#fff",
    items: [{
      key: "medical_and_recreational_complete",
      value: "",
      custom: true
    },
    ],
  },
];

export default PricingCardData;
