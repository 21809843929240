import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const UserRoutes = () => {

    const { token, isAdmin } = useToken();
    return (token && !isAdmin) ? <Outlet /> : <Navigate to="/login" />
}

export default UserRoutes;