// Libraries
import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Card, Button, List } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

// Componenets
import Layout from "../../../components/layout/Layout";
import MyButton from "../../../components/mybutton/MyButton";

// Style
import "./index.css";
import AnalysisModal from "../../../components/user/analysismodal/AnalysisModal";
import useToken from "../../../hooks/useToken";
import { useDispatch } from "react-redux";
import interceptor from "../../../shared/interceptor";
import { success } from "../../../components/Notifications";

const { Title, Text } = Typography;
function NewAnalysisOrder() {
  const { token } = useToken()
  const dispatch = useDispatch()
  const [packages, setPackages] = useState(null)
  const [discount, setDiscount] = useState(null)
  const naviagte = useNavigate();
  const { packageId, price } = useParams()



  const buyPackage = (id, price) => {

    interceptor.axiosGet('GET', `create-stripe-intent-for-regular-user/?selected_package=${id}&credits=${price}`, token).then((res) => {
      if (res.status === 200) {
        let CLIENT_SECRET = res.data.client_secret;
        naviagte(`/check-out/${CLIENT_SECRET}/null/${id}/${price}/${res.data.amount}`);
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  useEffect(() => {
    if (!packages && !packageId && !price) {
      getPackage()
    }
  })


  const getPackage = () => {
    interceptor.axiosGet('GET', 'get-price-details-for-user/', token).then((res) => {
      if (res.status === 200) {
        setPackages(res.data.response)
        setDiscount(res.data.discount)
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }
  useEffect(() => {
    if (packageId && price) {
      interceptor.axiosPost('Post', 'success-of-payment-for-credits/', {
        "selected_package": packageId,
        "credits": price
      }, token).then((res) => {
        if (res.status === 200) {
          success("topRight", res.data.message)
          getPackage()
        }
        else {
          console.log("error getting user : ", res)
        }
      }).catch((err) => {
        return err
      })
    }
  }, [packageId, price])

  return (
    <Layout title="Start a New Analysis Order">
      {/* <Row gutter={16}>
        <Col className="gutter-row" xl={18} lg={24} md={24} sm={24} xs={24}>
          
        </Col>
      </Row> */}
      {/*   <div className="new-analysis-order">
        <Title level={1}>Scientific COA Effect Analysis</Title>
        <div style={{ textAlign: "center" }}>
          <Link
            className="my-link"
            to="/analysis-depth-tier"
            style={{ background: "#FB920B", color: "#fff" }}
          >
            Use Analysis Credits
          </Link>
        </div>
        <div className="purchase-cradit">
          <Title level={2} style={{ marginBottom: '0px' }}>Order an Analysis or Purchase Credits</Title>
        </div>
      </div>

    <div className="modal-body analysis" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="analysis-header" >
          <List
            header={
              <div className="list-header-top ">
                <div className="list-header-top-border" >
                  <div>
                    <Title level={2} className="pricedollar">$</Title>
                    <Title level={2} style={{ paddingTop: '15px', marginBlock: '0px' }}>{packages ? packages[1].price : ''}</Title>
                    <Title level={4} style={{ marginTop: '0px' }}>/COA</Title>
                  </div>
                </div>
                <Title level={4} style={{ marginTop: '15px', borderBottom: '1px solid #fff' }}>6 Therapeutic</Title>

              </div>
            }
            style={{ background: ' linear-gradient(#59cab9, #4786ef)', minHeight: '350px' }}

          >
            <List.Item>
              <Typography.Text>Sleep Aid</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Anti-Anxiety</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>PTSD</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Depression</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Anti-Inflammatory</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Pain Reduction</Typography.Text>
            </List.Item>
          </List>
          <div className="card-bottom-content">
            <Link
              className="my-link analzie-coas "
              to="/start-order/7"
              style={{ background: 'linear-gradient(#4786ef,#59cab9 )' }}
            >
              ANALYZE COAs
            </Link>
            <a
              className="my-link"
              // to="#"
              onClick={() => buyPackage(7, 500)}
              style={{ background: 'linear-gradient( #4786ef,#59cab9)', color: "#fff" }}
            >
              {packages ? packages[1].off_text_500 : ''}
            </a>
            <a
              className="my-link"
              onClick={() => buyPackage(7, 1000)}
              style={{ background: "linear-gradient(#4786ef,#59cab9)", color: "#fff" }}
            >
              {packages ? packages[1].off_text_1000 : ''}
            </a>
          </div>

        </div>
        <div className="analysis-header" >
          <List
            header={
              <div className="list-header-top ">
                <div className="list-header-top-border" style={{ background: 'linear-gradient(#ec7979, #e54a64)' }} >
                  <div>
                    <Title level={2} className="pricedollar">$</Title>
                    <Title level={2} style={{ paddingTop: '15px', marginBlock: '0px' }}>  {packages ? packages[0].price : ''}</Title>
                    <Title level={4} style={{ marginTop: '0px' }}>/COA</Title>
                  </div>
                </div>
                <Title level={4} style={{ marginTop: '15px', borderBottom: '1px solid #fff' }}>6 Adult Use</Title>

              </div>
            }
            style={{ background: ' linear-gradient(#ec7979, #e54a64)', minHeight: '350px' }}

          >
            <List.Item>
              <Typography.Text>Stress Relief</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Creative</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Focused</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Couchlock</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Happy</Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>Comfortable</Typography.Text>
            </List.Item>
          </List>
          <div className="card-bottom-content">
            <Link
              className="my-link analzie-coas analzie-coas2"
              to="/start-order/6"
              style={{ background: 'linear-gradient(#e54a64, #ec7979 )' }}
            >
              ANALYZE COAs
            </Link>
            <a
              className="my-link"
              // to="#"
              onClick={() => buyPackage(6, 500)}
              style={{ background: 'linear-gradient(#e54a64, #ec7979)', color: "#fff" }}
            >
              {packages ? packages[0].off_text_500 : ''}
            </a>
            <a
              className="my-link"
              onClick={() => buyPackage(6, 1000)}
              style={{ background: "linear-gradient(#e54a64, #ec7979)", color: "#fff" }}
            >
              {packages ? packages[0].off_text_1000 : ''}
            </a>
          </div>

        </div>
        <div className="analysis-header" >
          <List
            header={
              <div className="list-header-top ">
                <div className="list-header-top-border" style={{ background: 'linear-gradient(#b821fe, #e52fff)' }}>
                  <div>
                    <Title level={2} className="pricedollar">$</Title>
                    <Title level={2} style={{ paddingTop: '15px', marginBlock: '0px' }}>{packages ? packages[2].price : ''}</Title>
                    <Title level={4} style={{ marginTop: '0px' }}>/COA</Title>
                  </div>
                </div>
                <Title level={4} style={{ marginTop: '15px', borderBottom: '1px solid #fff' }}>Combined Analysis</Title>

              </div>
            }
            style={{ background: ' linear-gradient(#b821fe, #e52fff)', minHeight: '350px' }}

          >
            <List.Item>
              <Typography.Text>6 Therapeutic Effect </Typography.Text>
            </List.Item>
            <List.Item>
              <Typography.Text>6 Adult Use Effect </Typography.Text>
            </List.Item>
          </List>
          <div className="card-bottom-content">
            <Link
              className="my-link analzie-coas analzie-coas3"
              to="/start-order/8"
              style={{ background: 'linear-gradient(#e52fff, #b821fe)' }}
            >
              ANALYZE COAs
            </Link>
            <a
              className="my-link"
              // to="#"
              onClick={() => buyPackage(8, 500)}
              style={{ background: 'linear-gradient( #e52fff, #b821fe)', color: "#fff" }}
            >
              {packages ? packages[2].off_text_500 : ''}
            </a>
            <a
              className="my-link"
              onClick={() => buyPackage(8, 1000)}
              style={{ background: "linear-gradient(#e52fff, #b821fe)", color: "#fff" }}
            >
              {packages ? packages[2].off_text_1000 : ''}
            </a>
          </div>

        </div>
      </div> */}


      <div className="new-analysis-order">

        <div className="purchase-cradit">
          <div>
            <Title level={1}>Scientific COA Effect Analysis</Title>
            <div style={{ textAlign: "center" }}>
              <Link
                className="my-link"
                to="/view-orders"
                style={{ background: "#609BE5", color: "#fff", marginBottom: '25px',marginRight: '20px' }}
              >
                View Order Results
              </Link>
              <Link
                className="my-link"
                to="/analysis-depth-tier"
                style={{ background: "#609BE5", color: "#fff", marginBottom: '25px' }}
              >
                Use Analysis Credits
              </Link>
            </div>
          </div> {/* <Title level={2}>Order an Analysis or Purchase Credits</Title> */}
          <Row gutter={24} >
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={12} xs={24}>
              <Card style={{ padding: '0 20px' }}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>6 Adult Use</Title>
                    <Title level={5}>Effects analyzed</Title>
                  </div>
                }
                extra={
                  <>
                    <Title level={1}>${packages ? packages[0].price : ''}</Title>
                    <Text>Per COA</Text>
                  </>
                }
              >
                <div className="purchase-list-text">
                  <List
                  >
                    <List.Item>
                      <Typography.Text>Stress Relief</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Creative</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Focused</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Couchlock</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Happy</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Comfortable</Typography.Text>
                    </List.Item>
                  </List>
                </div>

                <div className="card-bottom-content">
                  <Link
                    className="my-link"
                    to="/start-order/6"
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    Analyze Now
                  </Link>
                  {/* <a
                    className="my-link"
                    // to="#"
                    onClick={() => buyPackage(6, 500)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[0].off_text_500 : ''}
                  </a>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(6, 1000)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[0].off_text_1000 : ''}
                  </a> */}
                </div>
              </Card>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={12} xs={24}>
              <Card style={{ padding: '0 20px' }}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>6 Therapeutic</Title>
                    <Title level={5}>Effects analyzed</Title>
                  </div>
                }
                extra={
                  <>
                    <Title level={1}>${packages ? packages[1].price : ''}</Title>
                    <Text>Per COA</Text>
                  </>
                }
              >
                <div className="purchase-list-text">
                  <List
                  >
                    <List.Item>
                      <Typography.Text>Sleep Aid</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Anti-Anxiety</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>PTSD</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Depression</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Anti-Inflammatory</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>Pain Reduction</Typography.Text>
                    </List.Item>
                  </List>
                </div>
                <div className="card-bottom-content">
                  <Link
                    className="my-link"
                    to="/start-order/7"
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    Analyze Now
                  </Link>
                  {/* <a
                    className="my-link"
                    onClick={() => buyPackage(7, 500)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[1].off_text_500 : ''}
                  </a>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(7, 1000)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[1].off_text_1000 : ''}
                  </a> */}
                </div>
              </Card>
            </Col>
            <Col className="gutter-row" xl={8} lg={8} md={8} sm={12} xs={24}>
              <Card style={{ padding: '0 20px' }}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>12 Effects Analyzed</Title>
                    <Title level={5}>Combined Analysis</Title>
                  </div>
                }
                extra={
                  <>
                    <Title level={1}>${packages ? packages[2].price : ''}</Title>
                    <Text>Per COA</Text>
                  </>
                }
              >
                <div className="purchase-list-text purchase-list-text-last" >
                  <List
                  >
                    <List.Item>
                      <Typography.Text>6 Adult Use Effects</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <Typography.Text>6 Therapeutic Effects</Typography.Text>
                    </List.Item>
                    <List.Item>
                      <div className="experience">
                        <Typography.Text>
                        Includes an analysis of all Adult Use and Therapeutic Effects

                        </Typography.Text>
                      </div>
                    </List.Item>

                  </List>
                </div>
                <div className="card-bottom-content">
                  <Link
                    className="my-link"
                    to="/start-order/8"
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    Analyze Now
                  </Link>
                  {/* <a
                    className="my-link"
                    onClick={() => buyPackage(8, 500)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[2].off_text_500 : ''}
                  </a>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(8, 1000)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[2].off_text_1000 : ''}
                  </a> */}
                </div>
              </Card>
            </Col>
            {/* <Col className="gutter-row" xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card
                title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>Therapeutic & Adult Use</Title>
                    <Title level={5}>Advanced</Title>
                  </div>
                }
                extra={
                  <>
                    <Title level={1}>$  {packages ? packages[3].price : ''}</Title>
                    <Text>Per COA</Text>
                  </>
                }
              >
                <div className="card-content-outer">
                  <div className="card-content">
                    <Title level={4}>10 Therapeutic Effects</Title>
                    <AnalysisModal />
                  </div>
                  <div className="card-content">
                    <Title level={4}>10 Adult Use Effects</Title>
                    <AnalysisModal />
                  </div>
                </div>
                <div className="card-bottom-content">
                  <Link
                    className="my-link"
                    to="/start-order/4"
                    style={{ background: "#1C69C2", color: "#fff" }}
                  >
                    Order Analysis
                  </Link>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(3, 500)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[3].off_text_500 : ''}
                  </a>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(3, 1000)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[3].off_text_1000 : ''}
                  </a>
                </div>
              </Card>
            </Col>
             <Col className="gutter-row" xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card
                title={
                  <div style={{ textAlign: "center" }}>
                    <Title level={5}>Therapeutic & Adult Use</Title>
                    <Title level={5}>Complete</Title>
                  </div>
                }
                extra={
                  <>
                    <Title level={1}>$  {packages ? packages[4].price : ''}</Title>
                    <Text>Per COA</Text>
                  </>
                }
              >
                <div className="card-content-outer">
                  <div className="card-content">
                    <Title level={4}>20 Therapeutic Effects</Title>
                    <AnalysisModal />
                  </div>
                  <div className="card-content">
                    <Title level={4}>10 Adult Use Effects</Title>
                    <AnalysisModal />
                  </div>
                </div>
                <div className="card-bottom-content">
                  <Link
                    className="my-link"
                    to="/start-order/5"
                    style={{ background: "#BB1F89", color: "#fff" }}
                  >
                    Order Analysis
                  </Link>
                  <a
                    className="my-link"
                    onClick={() => buyPackage(4, 500)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[4].off_text_500 : ''}
                  </a>
                  <Link
                    className="my-link"
                    onClick={() => buyPackage(4, 1000)}
                    style={{ background: "#609BE5", color: "#fff" }}
                  >
                    {packages ? packages[4].off_text_1000 : ''}
                  </Link>
                </div>
              </Card>
            </Col> 
            <Col className="gutter-row" xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card title={
                <div style={{ textAlign: "center" }}>
                  <Title level={5}>Small Bulk Discounts</Title>
                </div>
              }
              >
                <ul className="bulk-discount-list">
                  <li>10% Discount at {discount?.twenty_pdfs_discount} COAs</li>
                  <li>15% Discount at {discount?.fifty_pdfs_discount} COAs</li>
                  <li>20% Discount at {discount?.one_fifty_pdfs_discount} COAs</li>
                  <li>25% Discount at {discount?.three_hundred_pdfs_discount} COAs</li>
                </ul>
              </Card>
            </Col>*/}
          </Row>
        </div>
      </div>
    </Layout>
  );
}

export default NewAnalysisOrder;
