// Libraries
import React, { useEffect } from "react";
import { Typography, Row, Col, Card, List } from "antd";
import { Link, useNavigate } from "react-router-dom";

// Components
import Layout from "../../../components/layout/Layout";

// Style
import "./index.css";
import AnalysisModal from "../../../components/user/analysismodal/AnalysisModal";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useState } from "react";

const { Title, Text } = Typography;
function MonthlyAnalysisCredits() {
  const [userCredits, setUserCredits] = useState()
  // const userCredits = useSelector((state) => state.userReducer.userCredits)
  const dispatch = useDispatch()
  const { token, userID } = useToken()
  const naviagte = useNavigate();

  useEffect(() => {
    if (!userCredits) {
      userActions.getUserCredits(token, userID).then(res => {
        setUserCredits(res)
      })
      // dispatch(userActions.getUserCredits(token, userID))
    }
  })

  return (
    <Layout title="New Analysis Order">
      <div className="analysis-depth-tire">
        <Title className='monthlyTitle' level={1}>Scientific COA Effect Analysis</Title>
        <div className="effect-analysis">
          <Row align="top">
            <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={8}>
              <Title level={2}>Monthly Analysis Credits</Title>
              <List className="remainCredit">
                <List.Item>
                  <Title level={5}>Remaining Analysis Credits</Title>
                </List.Item>
                <List.Item>
                  <Title level={5}>Cannabis Effects Analyzed</Title>
                </List.Item>
              </List>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={16}>
              <div className="effect-analysis-card">
              <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#ec7979",
                      }}
                    >
                      <Title level={4}>6</Title>
                      <Title level={4}>Adult Use</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.package_6} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.package_6 > 0) {
                          naviagte("/subscription-user/6")
                        }
                      }
                      }>Use</a>
                    </div>
                    <div className="effect-analysis-card-button">
                      <Link to="/new-analysis-order">Get Credits</Link>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#59cab9",
                      }}
                    >
                      <Title level={4}>6</Title>
                      <Title level={4}>Therap</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.package_7} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.package_7 > 0) {
                          naviagte("/subscription-user/7")
                        }
                      }
                      }>Use</a>
                    </div>
                    <div className="effect-analysis-card-button">
                      <Link to="/new-analysis-order">Get Credits</Link>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#b821fe",
                      }}
                    >
                      <Title level={4}>6 Therap</Title>
                      <Title level={4}>6 Adult</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.package_8} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.package_8 > 0) {
                          naviagte("/subscription-user/8")
                        }
                      }
                      }>Use</a>
                    </div>
                    <div className="effect-analysis-card-button">
                      <Link to="/new-analysis-order">Get Credits</Link>
                    </div>
                  </div>
                </Card>
                {/*}   <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#FBB703",
                      }}
                    >
                      <Title level={4}>10</Title>
                      <Title level={4}>Adult Use</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.recreational_basic_credits} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.recreational_basic_credits > 0) {
                          naviagte("/subscription-user/1")
                        }
                      }
                      }>Use</a>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#5FD029",
                      }}
                    >
                      <Title level={4}>5</Title>
                      <Title level={4}>Therapeutic</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.medical_basic_credits} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.medical_basic_credits > 0) {
                          naviagte("/subscription-user/2")
                        }
                      }
                      }>Use</a>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#57268D",
                      }}
                    >
                      <Title level={4}>5 Therapeutic</Title>
                      <Title level={4}>10 Adult Use</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.medical_and_recreational_basic} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.medical_and_recreational_basic > 0) {
                          naviagte("/subscription-user/3")
                        }
                      }
                      }>Use</a>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#1C69C2",
                      }}
                    >
                      <Title level={4}>10 Therapeutic</Title>
                      <Title level={4}>10 Adult Use</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.medical_and_recreational_advanced} Credits</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.medical_and_recreational_advanced > 0) {
                          naviagte("/subscription-user/4")
                        }
                      }
                      }>Use</a>
                    </div>
                  </div>
                </Card>
                <Card
                  title={
                    <div
                      className="effect-analysis-card-header"
                      style={{
                        background: "#BB1F89",
                      }}
                    >
                      <Title level={4}>20 Therapeutic</Title>
                      <Title level={4}>10 Adult Use</Title>
                    </div>
                  }
                  extra={
                    <>
                      <Title level={3}>{userCredits?.medical_and_recreational_complete}</Title>
                    </>
                  }
                >
                  <div className="effect-analysis-card-content">
                    <AnalysisModal />
                    <div className="effect-analysis-card-button">
                      <a onClick={() => {
                        if (userCredits?.medical_and_recreational_complete > 0) {
                          naviagte("/subscription-user/5")
                        }
                      }
                      }>Use</a>
                    </div>
                  </div>
                </Card> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}

export default MonthlyAnalysisCredits;
