// Libraries
import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Form, Button, Input, Radio, List, InputNumber } from "antd";
import PricingCard from "../../../components/admin/pricingcard/PricingCard";
import PricingCardData, { ManuallyPricingCardData, PricingCardRegularData } from "../../../data/admin/PricingCardData";
import DiscountSetting from "../discountsetting/DiscountSetting";
import MyButton from "../../../components/mybutton/MyButton";
import CustomPricing from "../custompricing/CustomPricing";
import ClientPricingListData from "../../../data/admin/ClientPricingListData";
import { useParams } from "react-router-dom";
import SubscriptionPricingListData from "../../../data/admin/SubscriptionPricingListData";

// Style
import "./style.css";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import useToken from "../../../hooks/useToken";
import { error, success } from "../../Notifications";
import interceptor from "../../../shared/interceptor";

const { Title, Text } = Typography;

function Pricing({ check, onUpdate, credit, regCredit, regPricingData, subPricingData }) {
  const { id } = useParams();
  const [manualForm] = Form.useForm();
  const { token, userID } = useToken()


  const onUpdateCredit = (value) => {
    onUpdate(value)
  }
  const onFinishManual = (values) => {
    console.log(values)
    debugger
    values.user = id
    if (Object.keys(values).length != 0) {
      userActions.updateUserCredit(token, values).then(user => {
        manualForm.resetFields();
        success("topRight", "Credit added to user Successfully")
      })
    }
    else {
      error("topRight", "Please added Credit")

    }
  }

  return (
    <>
      {/* <div className="user-pricing-title">
        <Title level={1}>Custom Pricing: Non-Subscription (New User)</Title>
      </div>
      <div className="custom-pricing">
        <div className="custom-pricing-header">
          <div className="custom-pricing-left">
            <h2>Client Custom Pricing</h2>
            <div className="global-discount">
              <p>Global Discount:</p>
              <Form.Item
                name="discount"
              >
                <Input defaultValue="10%" placeholder="" />
              </Form.Item>
            </div>
          </div>
          <div className="custom-pricing-right">
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <div className="custom-pricing-colored-outer">
                  <div className="custom-pricing-colored bg-orange">
                    <h2>10 <br /> Rec</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <div className="custom-pricing-colored-outer">
                  <div className="custom-pricing-colored bg-green">
                    <h2>5 <br /> Medical</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <div className="custom-pricing-colored-outer">
                  <div className="custom-pricing-colored bg-purple">
                    <h2>5 Med <br /> 10 Rec</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <div className="custom-pricing-colored-outer">
                  <div className="custom-pricing-colored bg-blue">
                    <h2>10 Med <br /> 10 Rec</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <div className="custom-pricing-colored-outer">
                  <div className="custom-pricing-colored bg-pink">
                    <h2>20 Med <br /> 10 Rec</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-pricing-body">
          <div className="custom-pricing-left">
            <h2>Single PDF (COA) Analysis</h2>
          </div>
          <div className="custom-pricing-right">
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <p>$1</p>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <p>$2</p>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <p>$3</p>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <p>$4</p>
              </div>
            </div>
            <div className="custom-pricing-card-outer">
              <div className="custom-pricing-card">
                <p>$5</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {
        !check ?
          <>
            <div className="user-pricing-title">
              <Title level={1}>Custom Pricing: Non-Subscription (New User)</Title>
            </div>
            <div className="custom-pricing">
              < Row align="top" >
                <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={6}>
                  <CustomPricing
                    title="Client Custom Pricing"
                    data={ClientPricingListData}
                    global={true}
                  />
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={18}>
                  <div className="pricing-content">
                    {regPricingData?.map((item, index) => (
                      <PricingCard item={item} key={index} checked={check} onUpdateCredit={onUpdateCredit} credit={credit} regCredit={regCredit} />
                    ))}
                  </div>
                </Col>
              </Row >
              <Row align="top">
                <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={6}>
                  <div className="discount-setting">
                    <Title level={5}>Discount Settings</Title>
                    <DiscountSetting />
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={18}>
                  <Title level={5}>Manually Add Analysis Credits</Title>
                  <div className="pricing-content">
                    <Form
                      style={{ display: "flex", width: "100%" }}
                      onFinish={onFinishManual}
                      // onFinishFailed={onFinishFailed}
                      form={manualForm}
                    >
                      {ManuallyPricingCardData.map((item, index) => (
                        // <PricingCard item={item} key={index} onUpdateCredit={onUpdateCredit} />

                        <List className="pricing-title">
                          <List.Item style={{ background: item.background }}>
                            <Typography.Text>{item.topTitle}</Typography.Text>
                            <Typography.Text>{item.bottomTitle}</Typography.Text>
                          </List.Item>
                          {item.items.map((dollar, index2) => (
                            <List.Item
                              id={dollar.key}
                              key={index2}
                            >
                              <Form.Item
                                name={dollar?.key}
                                rules={[
                                  {
                                    pattern: new RegExp(/^[0-9]*$/),
                                    message: 'Please Enter Number only',

                                  }
                                ]}>
                                <InputNumber
                                  placeholder=""
                                  min={0}
                                />
                              </Form.Item>
                            </List.Item>
                          ))}
                          {/* </>
                        )}
                      </Form.List> */}
                        </List>


                      ))}
                    </Form>

                  </div>
                  <div className="price-card-btn">
                    {id && <Button
                      className="my-button"
                      style={{ backgroundColor: "#609BE5", color: "#fff", width: "initial", display: "inline-block" }}
                      onClick={() => onFinishManual(manualForm.getFieldValue())}
                    >
                      Add Credits to User
                    </Button>}
                  </div>
                </Col>
              </Row>
            </div >
          </>
          :
          <>
            <div className="client-pricing-title">
              <Title level={1}>Select New User's Subscription Plan</Title>
            </div>
            <div className="scroll">
              <div className="user-pricing-card custom-pricing">
                <Row>
                  <Col className="gutter-row" xs={8} sm={8} md={8} lg={8} xl={6}>
                    <CustomPricing
                      title="Define Subscription Pricing"
                      data={SubscriptionPricingListData}
                      global={true}

                    />
                  </Col>
                  <Col className="gutter-row" xs={16} sm={16} md={16} lg={16} xl={18}>
                    <div className="pricing-content user-subs-plan">
                      {subPricingData?.map((item, index) => (
                        <PricingCard
                          background={item.background}
                          item={item} key={index} onUpdateCredit={onUpdateCredit}
                          credit={credit}
                          checked={check}
                        />
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
      }
    </>
  );
}

export default Pricing;
