const SubscriptionPricingListData = [
  {
    item: "10 Strains per month",
  },
  {
    item: "Custom Pricing (10 Strains)",
  },
  {
    item: "25 Strains per month",
  },
  {
    item: "Custom Pricing (25 Strains)",
  },
  {
    item: "50 Strains per month",
  },
  {
    item: "Custom Pricing (50 Strains)",
  },
  {
    item: "Unlimited Strains per month",
  },
  {
    item: "Custom Pricing (100 Strains)",
  },
];

export default SubscriptionPricingListData;

export const SubscriptionPricingListDataAdd = [
  {
    item: "10 Strains per month",
  },
  
  {
    item: "25 Strains per month",
  },
    {
    item: "50 Strains per month",
  },
  {
    item: "Unlimited Strains per month",
  },
  
];