import React, { useEffect, useState } from "react";
import { Typography, List, Input, Form, Radio, InputNumber } from "antd";

import "./style.css";
import PricingCardData from "../../../data/admin/PricingCardData";

function PricingCard(props) {
  const [form] = Form.useForm();
  const { credit, item, checked, regCredit } = props
  const changeValue = (values) => {
    const boxes = Array.from(document.getElementsByClassName("selected"));
    if (checked) {
      boxes.forEach((box) => {
        box.classList.remove("selected");
      });

      item?.items.forEach((x, index) => {
        console.log(x)
        if (x.custom)
          x.value = ''
        console.log(x)
      })
      Array.from(document.getElementsByClassName("customvalue")).forEach((el) => {
        el.value = "";
      });
    }

    document.getElementById(values.key).classList.add("selected");
    props.onUpdateCredit(values);

    // const boxes = document.getElementById(values.key);
    // if (boxes.classList.contains("selected")) {
    //   boxes.classList.remove("selected")
    // }
    // else {
    //   boxes.classList.add("selected");

    // }
    // debugger


    // if (checked) {
    //   const boxesSub = Array.from(document.getElementsByClassName("selected"));
    //   boxesSub.forEach((box) => {
    //     box.classList.remove("selected");
    //   });

    //   item?.items.forEach((x, index) => {
    //     console.log(x)
    //     if (x.custom)
    //       x.value = ''
    //     console.log(x)
    //   })
    //   Array.from(document.getElementsByClassName("customvalue")).forEach((el) => {
    //     el.value = "";
    //   });
    // }

    // // document.getElementById(values.key).classList.add("selected");
    // props.onUpdateCredit(values);
  };

  const updateValue = (event, obj) => {
    let value = event
    let boxes = Array.from(document.getElementsByClassName("customvalue"))
    if (checked) {
      item?.items.forEach((x, index) => {
        console.log(x)
        if (x.custom)
          x.value = ''
        console.log(x)
      })
      boxes.forEach((el) => {
        el.value = "";
      });
    }

    obj.value = value
    props.onUpdateCredit(obj);
  };

  useEffect(() => {
    if (credit) {
      if (document.getElementById(credit.key))
        document.getElementById(credit.key).classList.add("selected");
      let check = document.getElementById(credit?.key + '_input')
      if (check)
        check.value = credit.value
    }
    if (regCredit) {
      regCredit.forEach(x => {
        let id = document.getElementById(x?.key)
        if (id)
          id.classList.add("selected");
        let check = document.getElementById(x?.key + '_input')
        if (check)
          check.value = x.value
      })
    }
  })

  return (
    <List className="pricing-title">
      <List.Item style={{ background: props.item.background }}>
        <Typography.Text>{props.item.topTitle}</Typography.Text>
        <Typography.Text>{props.item.bottomTitle}</Typography.Text>
      </List.Item>
      {item.items.map((dollar, index2) => (
        <List.Item
          id={dollar.key}
          key={index2}
          className={dollar.checek ? "selected" : ""}
          onClick={() => changeValue(dollar)}
        >
          {!dollar.custom ? (
            <Typography.Text
              style={
                props?.background
                  ? { background: props.background }
                  : { color: "black" }
              }
            >
              ${dollar.value}
            </Typography.Text>
          ) : (
            // </Form.Item>
            <InputNumber
              min={0}
              placeholder=""
              id={dollar?.key + '_input'}
              className={`customvalue ` + credit?.key}
              onChange={(e) => updateValue(e, dollar)}
            />
          )}
        </List.Item>
      ))}
      {/* </>
        )}
      </Form.List> */}
    </List>
  );
}

export default PricingCard;
