import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const UserRoutes = () => {

    const { isPaymentDue,accountType } = useToken();
    return !isPaymentDue || accountType ? <Outlet /> : <Navigate to="/profile" />
}

export default UserRoutes;