// Libraries
import React, { useEffect, useState } from "react";
import { Table, Typography, Tag, Button, Select, Row, Col } from "antd";
import { Link } from "react-router-dom";

// Componenets
import Layout from "../../../components/layout/Layout";

// Style
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import Input from "antd/es/input/Input";

const { Title, Text } = Typography;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
function UserManagement() {
  const userList = useSelector((state) => state.userReducer.userList)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  debugger
  const [filter, setFilter] = useState(userList?.users)

  const { token } = useToken()
  const columns = [
    {
      title: "Company name",
      dataIndex: "company",
      key: "company",
      width: 100,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    {
      title: "View user",
      dataIndex: "viewuser",
      key: "viewuser",
      width: 150,
      render: (tags, item) => (
        <span>
          <Link to={`/edit-user/${item.id}`}>Modify</Link>
        </span>
      ),
    },
    {
      title: "Sign Up Date",
      dataIndex: "date_joined",
      key: "date_joined",
      width: 150,
      render: (tags, item) => (
        new Date(item.date_joined).toLocaleString()
      )
    },
    {
      title: "User Full Name",
      dataIndex: "full_name",
      key: "full_name",
      width: 150,
    },
    {
      title: "Account Type",
      key: "account_type",
      dataIndex: "account_type",
      width: 150,
    },
    {
      title: "Status",
      key: "account_status",
      dataIndex: "account_status",
      width: 150,
      render: (tags) => (
        <span>
          <Tag color={tags === "PAID" ? "green" : "red"} key={tags}>
            {tags.toUpperCase()}
          </Tag>
        </span>
      ),
    },
  ];

  useEffect(() => {
    setLoading(false)
    if (userList?.length === 0) {
      setLoading(true)
      dispatch(userActions.getUser(token))
    }
  })

  useEffect(() => {
    if (userList?.users) {
      console.log(1)
      setFilter(userList.users)
    }
  }, [userList])

  const searchOrder = (value) => {
    let search = value.target.value.toLowerCase()
    if (userList?.users.length > 0) {
      setFilter(userList?.users.filter(x => x.company.toLowerCase().includes(search) || x.email?.toLowerCase().includes(search)
        || x.full_name?.toLowerCase().includes(search)))
    }
  }
  return (
    <Layout title="Admin Dashboard">
      <div className="user-manage-table-header">
        <div className="table-selects">
          <Title level={3}>User Management</Title>
          {/* <div className="show-select">
            <Text>Show:</Text>
            <Select

              defaultValue="5"
              onChange={handleChange}
              options={[
                { value: "5", label: "5" },
                { value: "10", label: "10" },
                { value: "15", label: "15" },
              ]}
            />
          </div> */}
        </div>
        {/* <div className="sort-selects">
          <Text>Sort by:</Text>
          <Select
            defaultValue="default"
            onChange={handleChange}
            options={[
              { value: "newest", label: "Newest" },
              { value: "oldest", label: "Oldest" },
            ]}
          />
        </div> */}
      </div>
      <Row>
        <Col md={16} xl={16} lg={16} sm={24} xs={24}>
          <Input type="text" className="searchInput" placeholder={`Search User`} onKeyUp={searchOrder}></Input>
        </Col>
      </Row>
      <div className="user-management-table">
        <Table
          columns={columns}
          dataSource={filter}
          loading={loading}
        // scroll={{ 
        //   x: 1500,
        //   y: 300,
        // }}
        />
      </div>
    </Layout>
  );
}

export default UserManagement;
