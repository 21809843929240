// Libraries
import React, { useEffect, useState } from "react";
import { Typography, Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Components
import Layout from "../../../components/layout/Layout";
import Pricing from "../../../components/admin/pricing/Pricing";
import MyButton from "../../../components/mybutton/MyButton";
import AddNewUser from "../../../components/admin/addnewuserform/AddNewUser";

// Styles
import "./index.css";
import useToken from "../../../hooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import PricingCardData from "../../../data/admin/PricingCardData";
import interceptor from "../../../shared/interceptor";
import { error } from "../../../components/Notifications";

const { Title } = Typography;
function CreateNewUser() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [item, setItem] = useState(null)
  const [credit, setCredit] = useState(null)
  const [regCredit, setRegCredit] = useState([])
  const { token } = useToken()
  const dispatch = useDispatch()
  const userList = useSelector((state) => state.userReducer.userList)
  const [check, setCheck] = useState()
  const [loading, setLoading] = useState()
  const naviagte = useNavigate();
  const [regPricingData, setRegPricingData] = useState(null)
  const [subPricingData, setSubPricingData] = useState(null)

  const onFinish = (values) => {
    debugger

    setLoading(true)
    values.credits = null
    values.user_credits_info = []
    if (!check) {
      values.pricing_data = JSON.stringify(regPricingData)
      regCredit.forEach(x => {
        // if (Object.keys(x).length > 0 && (x.key && x.key.length > 0)) {
        values.user_credits_info.push({
          [x.key]: x.value
        })
        // }
      })
      if (values.user_credits_info.length == 0) {
        setLoading(false)
        error("topRight", "Please add Pricing to user")
        return
      }
    }
    else {
      values.pricing_data = JSON.stringify(subPricingData)
      values.credits =
      {
        [credit.key]: credit.value
      }

      if (credit.value == 0) {
        setLoading(false)
        error("topRight", "Please add Pricing to user")
        return
      }
    }
    values.id = id
    console.log(values)

    userActions.addUser(token, values, id ? 'edit-user/' : 'register-user/').then(res => {
      setLoading(false)
      dispatch(userActions.getUser(token))
      naviagte("/user-management");

    })

  }

  const onFinishFailed = (e) => {
    console.log("Failed", e)
  }

  useEffect(() => {
    if (id && !item) {
      userActions.getUserDetail(token, id).then(user => {
        setCheck(user?.account_type == "SUBSCRIPTION" ? true : false)
        setItem(user)
        setCredit({
          key: Object.keys(user.credits),
          value: user.credits[Object.keys(user.credits)],
          custom: false
        })

        if (user?.account_type == "SUBSCRIPTION") {
          setSubPricingData(JSON.parse(user.pricing_data))
          getRegularPrice()
        } else {
          setRegPricingData(JSON.parse(user.pricing_data))
          getSubscriptionPrice()
        }
        if (user.user_credits_info.length > 0) {
          user.user_credits_info.forEach(x => {
            if (Object.keys(x)) {
              let obj = {
                key: Object.keys(x)[0],
                value: x[Object.keys(x)],
                custom: false
              }
              setRegCredit(oldArray => [...oldArray, obj])
            }

          })
        }
        form.setFieldsValue(user)
      })
    } else if (!regPricingData && !subPricingData) {
      getSubscriptionPrice()
      getRegularPrice()
    }
  })

  const onUpdate = (value) => {
    console.log(value)
    if (!check) {
      let indx = regCredit.filter((item) => item.key != value.key)
      setRegCredit(indx)
      setRegCredit(oldArray => [...oldArray, value]);
      // let indx = regCredit.find((item) => item.key == value.key)
      // if (!indx) {
      //   setRegCredit(oldArray => [...oldArray, value]);
      // } else {
      //   setRegCredit(regCredit.filter((item) => item.key != value.key))
      // }
    } else {
      setCredit(value)
    }
  }

  const onUpdateCheck = (value) => {
    console.log(value)
    setCheck(value)
  }

  useEffect(() => {
  }, [check])


  // useEffect(() => {
  //   if (!regPricingData) {
  //     getRegularPrice()
  //   }
  //   if (!subPricingData) {
  //     getSubscriptionPrice()
  //   }
  // }, [])

  const getRegularPrice = () => {
    interceptor.axiosGet('Get', 'get-user-pricing/?pricing_type=0', token).then((res) => {
      if (res.status === 200) {
        console.log((res.data.pricing_data))
        setRegPricingData(JSON.parse(res.data.pricing_data))
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }


  const getSubscriptionPrice = () => {
    interceptor.axiosGet('Get', 'get-user-pricing/?pricing_type=1', token).then((res) => {
      if (res.status === 200) {
        setSubPricingData(JSON.parse(res.data.pricing_data))
      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  return (
    <Layout title="Admin: User Management">

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <AddNewUser showCheckbox={true} user={item} form={form} onUpdateCheck={onUpdateCheck} />
        <Pricing onUpdate={onUpdate} check={check} credit={credit} regCredit={regCredit} regPricingData={regPricingData} subPricingData={subPricingData} />
        <div style={{ marginTop: "30px" }}>
          <Button
            className="my-button"
            style={{
              backgroundColor: "#609BE5",
              color: "#fff",
              width: "initial",
              display: "inline-block",
            }}
            htmlType="submit"

            loading={loading}
          >
            {id ? "Save" : "Add User (Standard Pricing)"}
          </Button>
        </div>
      </Form>
    </Layout>
  );
}

export default CreateNewUser;
