// Libraries
import React, { useEffect, useState } from "react";
import { Button, List, Typography, Tag, Input } from "antd";

// Components
import OrdersData from "../../../data/admin/OrdersData";
import { Pdf } from "../../../icons/Icons";
import { Link } from "react-router-dom";

// Style
import "./style.css";

const { Title, Text } = Typography;
function DeliverOrders(props) {
  const [filter, setFilter] = useState(props?.item)
  const searchOrder = (value) => {
    let search = value.target.value.toLowerCase()
    if (props?.item?.length > 0) {
      setFilter(props?.item.filter(x => x.name.toLowerCase().includes(search) || x.email?.toLowerCase().includes(search)
        || x.address?.toLowerCase().includes(search) || x.location?.toLowerCase().includes(search) || x.company_name.toLowerCase().includes(search) || x.id.toLocaleString().includes(value.target.value)))
    }
  }

  useEffect(() => {
    if (props?.item) {
      setFilter(props?.item)
    }

  }, [props?.item])

  return (
    <div className="orders">
      <List
        bordered
        header={
          <div className="searchBar">
            <Title level={5} style={{ marginBottom: 0 }}>{props.heading}
            </Title>
            <Input type="text" className="searchInput" placeholder={`Search ${props.heading}`} onKeyUp={searchOrder}></Input>
          </div>
        }
        footer={
          props.showFooter ? (
            <Button
              className="my-button"
              style={{ color: "#787878", padding: '0' }}
              htmlType="submit"
            >
              {props.text}
            </Button>
          ) : null
        }
      >
        {filter?.map((item, index) => (
          <List.Item
            key={index}
            actions={[
              <Tag
                style={{
                  backgroundColor: item?.background,
                  color: item.color,
                  border: "0",
                }}
              >
                {item.status}
              </Tag>,
              <Text style={{ padding: '0 6px !important' }}>{item.stripe_transaction_id ? '$' + item.order_details.order_total : '--'}</Text>,
            ]}
          >
            <div className="order-count">{index + 1}.</div>
            <List.Item.Meta
              avatar={<Pdf color="rgba(19, 119, 201, 1)" />}
              title={<Link to="#">{item.name}</Link>}
              description={<>
                <Typography>({item.files.length} PDF)  {item.company_name}</Typography>
                <Typography>{new Date(item.updated_at).toLocaleString()}</Typography>
                <Typography><b>Order #  {item.id}</b></Typography>
              </>
              }
            />

            <div className="order-btn">
              <Link to={`/deliver-order/${item.id}`} className="order-button">
                open
                {item.button}
              </Link>
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  );
}

export default DeliverOrders;
