import actionTypes from "./user.action.type"


const initialState = {
    user: {
        id: null,
        full_name: null,
        email: "",
        is_admin: false,
        account_type: '',
        location: null,
        phone_number: null,
        accepted_terms: false,
        profile_photo: null,
        is_admin: true,
        company: null,

    },
    id: '',
    token: '',
    refresh_token: '',
    userList: [],
    OrderPending: null,
    OrderPrcessing: null,
    OrderDelivered: null,
    userOrderList: [],
    userCredits: null,
    rememberMe: {
        isRememberMe: false,
        email: '',
        password: '',
    },
}


export const userReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case actionTypes.singIn:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                refresh_token: action.payload.refresh_token,
            }
        case actionTypes.users:
            return {
                ...state,
                userList: action.payload,
            }

        case actionTypes.clearChahce:
            return {
                initialState,
                user: {
                    id: null,
                    full_name: null,
                    email: "",
                    is_admin: false,
                    account_type: '',
                    location: null,
                    phone_number: null,
                    accepted_terms: false,
                    profile_photo: null,
                    is_admin: true,
                    company: null,

                },
                id: '',
                token: '',
                refresh_token: '',
                userList: [],
                OrderPending: null,
                OrderPrcessing: null,
                OrderDelivered: null,
                userOrderList: [],
                userCredits: null,
                rememberMe: {
                    isRememberMe: false,
                    email: '',
                    password: '',
                },
            }
        case actionTypes.user:
            return {
                ...state,
                user: action.payload,
            }
        case actionTypes.userOrderList:
            return {
                ...state,
                userOrderList: action.payload,
            }
        case actionTypes.userCredits:
            return {
                ...state,
                userCredits: action.payload,
            }
        case actionTypes.ordersPending:
            return {
                ...state,
                ordersPending: action.payload,
            }
        case actionTypes.orderProcessing:
            return {
                ...state,
                orderProcessing: action.payload,
            }
        case actionTypes.ordersDelivered:
            return {
                ...state,
                OrderDelivered: action.payload,
            }
        default:
            return state
    }
}


