// Libraries
import React, { useState } from "react";
import { Typography, Image, Checkbox, Form, Input, Button, Alert } from "antd";

// Components
import authLogo from "../../assets/images/logotm.png";
import MyButton from "../../components/mybutton/MyButton";
import { useShareDispatch } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./index.css";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../apis/auth";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";
import { success } from "../../components/Notifications";

const { Title, Paragraph } = Typography;

function ForgotPassword() {
  const naviagte = useNavigate();
  const [isSigning, setIsSigning] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saveLoginUser } = useToken();
  const [message, setMessage] = useState('')
  // const searchParams = useSearchParams();
  // const code = searchParams.get('code');

  const onFinish = async (values) => {
    console.log("Success:", values);
    setIsSigning(true);
    forgotPassword(values)
      .then(async (res) => {
        setIsSigning(false);
        if (res.status == 200) {
          success("topRight", res.data.message)
          naviagte("/login");
        }
        else {
          setMessage(res.message)
        }
      })
      .catch((error) => {
        setIsSigning(false);
      });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <section className="login">
      <div className="auth-bg">
        <div className="auth">
          <span className="auth-logo">
            <Image
              preview={false}
              src={authLogo}
              alt="Logo"
            />
          </span>
          <div className="login-form">
            <Title level={4}>Forgot Password</Title>
            <Form
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                  {
                    type: "email"
                  }
                ]}
              >
                <Input placeholder="Please Enter Email" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="my-button mt-2"
                  style={{ backgroundColor: "#609BE5", color: "#fff" }}
                  loading={isSigning}
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
              {message && <Alert type="error" message={message}></Alert>}
            </Form>
          </div>
        </div>
        {/* <div className="login-footer">
          <div className="footer-title">
            <Title level={3} style={{ color: '#fff', margin: '0', marginRight: '5px' }}> No Account?  </Title>
            <Title level={3} style={{ color: '#fff', margin: '0' }}> Sign Up In Seconds! </Title>
          </div>
          <div className="footerBtn">
            <Button
              className="signUpbtn"
            >
              <Link to="/register">  Sign up</Link>
            </Button>
            <Button
              className="loginUpbtn"
            >
              <Link to="/login"> Login</Link>
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default ForgotPassword;
