// Libraries
import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import StackedChart from "../../../components/stackedchart/StackedChart";
import DonutChart from "../../../components/donutchart/DonutChart";
import ColumnChart from "../../../components/columnchart/ColumnChart";
import ChartTable from "../../../components/charttable/ChartTable";

// Style
import "./index.css";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useToken from "../../../hooks/useToken";

const { Title } = Typography;

function ViewCsvVisual() {
  const { id } = useParams()
  const [orders, setOrders] = useState(null)
  const [visual, setVisual] = useState(null)
  const [subVisual, setSubVisual] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { token } = useToken()
  useEffect(() => {
    if (id && !orders) {
      userActions.getUserOrders(token, id).then(async res => {
        await setOrders(res)
        userActions.getVisualOrderDetail(token, id).then(async res => {
          await setVisual(res)
          userActions.getSubVisualDetail(token, id, 1).then(async res => {
            await setSubVisual(res)
          })

        })

      })
    }
  })

  const Onchange = (value) => {
    console.log(value)
    setLoading(true)
    userActions.getSubVisualDetail(token, id, value).then(async res => {
      await setSubVisual(res)
      setLoading(false)
    })
  }

  return (
    <Layout title={(orders?.company_name ? orders?.company_name + ' - ' : '') + (orders?.updated_at ? new Date(orders?.updated_at).toLocaleString() : '')} >
      <div className="deliver-order">
        <div className="area-chart">
          <StackedChart showTitle={true} item={visual} change={Onchange} title={orders?.id+' : '+ orders?.name} />
        </div>
        <div className="donut-chart-meta stacked-chart stacked-chart-selection">
          <Title style={{ marginBottom: '0px' }} level={5}>Selection: {subVisual?.strain_name}</Title>
          <ColumnChart item={subVisual} loading={loading} />
          <Row gutter={16} align="top" style={{ marginTop: 30 }}>
            <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
              <DonutChart id={'chart1'} item={subVisual?.donout_graph} loading={loading} title={'Terpene %'} subTitle={'Terpene Volume'} />
            </Col>
            <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
              <DonutChart id={'chart2'} item={subVisual?.donout_graph_2} loading={loading} title={'Cannabinoid %'} subTitle={'Cannabinoid Volume'} />
            </Col>
          </Row>
        </div>
        <ChartTable item={visual} />
      </div>
    </Layout>
  );
}

export default ViewCsvVisual;
