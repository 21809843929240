// Libraries
import React, { useState } from "react";
import { Typography, Row, Col, List, Button, Modal, Image } from "antd";

// Componenets
import modalLogo from "../../../assets/images/logo.svg";
// Style
import "./style.css";

const { Title, Text } = Typography;
function AnalysisModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="analysis-modal">
        <Button type="primary" onClick={showModal}>
          View Effects
        </Button>
        <Modal
          centered
          width="100%"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ maxWidth: "1300px" }}
        >
          <div className="modal-header">
            <div className="modal-logo">
              <Image preview={false} src={modalLogo} alt="Logo" />
            </div>
            <Title level={5}>Cannabis Effect Analysis Tiers</Title>
          </div>
          <div className="modal-body">
            <Row gutter={16}>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title level={4}>6</Title>
                      <Title level={4}>Adult Use</Title>
                    </div>
                  }
                  style={{ background: "#59cab9" ,height:"100%"}}
                >
                  <List.Item>
                    <Typography.Text>Stress Relief</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Creative</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Focused</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Couchlock</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Happy</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Comfortable</Typography.Text>
                  </List.Item>
                </List>
              </Col>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title level={4}>6</Title>
                      <Title level={4}>Therapeutic</Title>
                    </div>
                  }
                  style={{ background: "#ec7979" ,height:"100%"}}
                >
                  <List.Item>
                    <Typography.Text>Sleep Aid</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Anti-Anxiety</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>PTSD</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Depression</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Anti-Inflammatory</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Pain Reduction</Typography.Text>
                  </List.Item>
                </List>
              </Col>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title level={4}>Combined </Title>
                      <Title level={4}>Analysis</Title>
                    </div>
                  }
                  style={{ background: "#b821fe" ,height:"100%"}}
                >
                  <List.Item>
                    <Typography.Text>6 Therapeutic Effect </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>6 Adult Use Effect </Typography.Text>
                  </List.Item>
                </List>
              </Col>
              {/* <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title level={4}>10</Title>
                      <Title level={4}>Adult Use</Title>
                    </div>
                  }
                  style={{ background: "#fff" }}
                >
                  <List.Item>
                    <Typography.Text>General Wellbeing</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Stress Reduction</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Focus</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Calming</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Creative</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Couchlock</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Comfort</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Sports Performance</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Sports Recovery</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Sports Anxiety</Typography.Text>
                  </List.Item>
                </List>
              </Col>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title style={{ color: "#fff" }} level={4}>
                        5
                      </Title>
                      <Title style={{ color: "#fff" }} level={4}>
                      Therapeutic
                      </Title>
                    </div>
                  }
                  style={{ background: "#2AA9E2" }}
                >
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Sleep Aid
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Anxiety
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Anti-Inflammatory
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Diabetes
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Sedative
                    </Typography.Text>
                  </List.Item>
                </List>
              </Col>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title level={4}>10</Title>
                      <Title level={4}>Therapeutic</Title>
                    </div>
                  }
                  style={{ background: "#fff" }}
                >
                  <List.Item>
                    <Typography.Text>Depression</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Pain Reduction: Daytime</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>Pain Reduction: Evening & Nightime</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>PTSD</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>PMS</Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text>+ 5 Therapeutic Tier</Typography.Text>
                  </List.Item>
                </List>
              </Col>
              <Col className="gutter-row" xl={6} lg={12} md={16} sm={24} xs={24}>
                <List
                  header={
                    <div className="modal-list-header">
                      <Title style={{ color: "#fff" }} level={4}>
                        20
                      </Title>
                      <Title style={{ color: "#fff" }} level={4}>
                        Therapeutic
                      </Title>
                    </div>
                  }
                  style={{ background: "#2AA9E2" }}
                >
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Alzheimer's Disease
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Mood Stabilization
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      ADHD
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Neuroprotective
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Temperature Regulation
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Cognitive Functioning
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      REM Sleep Latency
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Non-REM Prolonging
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      General Sleep Latency
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      Multiple Scelrosis
                    </Typography.Text>
                  </List.Item>
                  <List.Item>
                    <Typography.Text style={{ color: "#fff" }}>
                      + 10 Therapeutic Tier
                    </Typography.Text>
                  </List.Item>
                </List>
              </Col> */}
            </Row>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default AnalysisModal;
