import React from "react";
import { Typography, List, Input, Form } from "antd";

const { Title } = Typography;

// import DiscountSettingData from "../../../data/admin/DiscountSettingData";

function DiscountSetting() {
  // const handleChangePrice = (index, value) => {
  //   const updatedData = [...DiscountSettingData];
  //   updatedData[index].price = value;

  // };

  // const transposedData = DiscountSettingData.reduce((acc, item) => {
  //   Object.entries(item).forEach(([key, value]) => {
  //     if (!acc[key]) {
  //       acc[key] = [];
  //     }
  //     acc[key].push(value);
  //   });
  //   return acc;
  // }, {});

  // const transposedElements = Object.entries(transposedData).map(
  //   ([key, values]) => (
  //     <List key={key}>
  //       {values.map((value, index) => (
  //         <List.Item key={index}>
  //           {key === "price" ? (
  //             <Input
  //               placeholder="0"
  //             />
  //           ) : (
  //             <Typography.Text>{value}</Typography.Text>
  //           )}
  //         </List.Item>
  //       ))}
  //     </List>
  //   )
  // );

  // return <div style={{ display: "flex" }}>{transposedElements}</div>;
  return (
    <>
      <div className="discount-list">
        <List>
          <List.Item>
            <Title level={2}>10% Discount</Title>
            <div className="discount-input">
              <Form.Item name="twenty_pdfs_discount"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Wrong format!"
                  }
                ]}>
                <Input placeholder="20" />
              </Form.Item>
            </div>
            <Title level={2}>COAs</Title>
          </List.Item>
          <List.Item>
            <Title level={2}>15% Discount</Title>
            <div className="discount-input">
              <Form.Item name="fifty_pdfs_discount"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Wrong format!"
                  },
                ]} >
                <Input placeholder="50" />
              </Form.Item>
            </div>
            <Title level={2}>COAs</Title>
          </List.Item>
          <List.Item>
            <Title level={2}>20% Discount</Title>
            <div className="discount-input">
              <Form.Item name="one_fifty_pdfs_discount"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Wrong format!"
                  },
                ]}>
                <Input placeholder="100" />
              </Form.Item>
            </div>
            <Title level={2}>COAs</Title>
          </List.Item>
          <List.Item>
            <Title level={2}>25% Discount</Title>
            <div className="discount-input">
              <Form.Item name="three_hundred_pdfs_discount"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Wrong format!"
                  },
                ]}
              >
                <Input placeholder="200" />
              </Form.Item>
            </div>
            <Title level={2}>COAs</Title>
          </List.Item>
        </List>
      </div>
    </>
  );
}

export default DiscountSetting;