// Libraries
import React, { useState } from "react";
import { Typography, Image, Checkbox, Form, Input, Button, Col, Row, Select, Modal } from "antd";

// Components
import authLogo from "../../assets/images/logotm.png";
import MyButton from "../../components/mybutton/MyButton";
import { useShareDispatch } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { SignUpUser, loginUser } from "../../apis/auth";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";
import { CustomerType } from "../../data/admin/DDL";
import TermCondition from "../termsCondition/termCondition";

const { Title, Paragraph } = Typography;

function SignUp() {
  const naviagte = useNavigate();
  const [isSigning, setIsSigning] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saveLoginUser } = useToken();

  const onFinish = async (values) => {
    setIsSigning(true);
    console.log("Success:", values);
    SignUpUser(values)
      .then(async (res) => {
        setIsSigning(false);
        if (res.status == 200) {
          // await saveLoginUser(res.data);
          // await dispatch({ type: actionTypes.singIn, payload: res.data });
          naviagte("/login");
        } else {
          form.setFields([
            {
              name: "password",
              errors: [res.message],
            },
            {
              name: "email",
              errors: [res.message],
            },
          ]);
        }
      })
      .catch((error) => {
        setIsSigning(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <section className="login" style={{ overflow: 'unset' }}>
      <div className="auth-bg">
        <div className="auth-signup auth-model" style={{ overflow: 'unset' }}>
          <span className="auth-logo">
            <Image
              preview={false}
              src={authLogo}
              alt="Logo"
            />
          </span>
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <div className="login-form" style={{ maxHeight: '43vh', overflow: 'auto' }}>
              <Title level={4}>Sign Up</Title>
              <Row gutter={10}>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                      {
                        type: "email"
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Full Name"
                    name="full_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Full Name',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Company Name"
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Company Name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Phone"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter PhoneNumber',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Industry"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Industry',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Industry"
                      showArrow
                      allowClear
                    >
                      {CustomerType.map((item) => (
                        <>
                          {
                            item.name !== 'Others' && item.name !== 'All' &&
                            <Select.Option key={item.key} value={item.name}>
                              {item.name}
                            </Select.Option>
                          }
                        </>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Country!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please input your State!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Zip Code"
                    name="zipCode"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter zipCode',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Address"
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Address',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>



            </div>
            <Row gutter={10} className="login-form-toc" >
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginR: '0 auto' }}>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please accept terms of service!",
                    },
                  ]}
                  name="remember" valuePropName="checked">
                  <Checkbox>
                    I have read and agree to the{" "}
                    <a href="#" onClick={showModal}>Terms of Service</a>

                    {/* <a target="_blank" href="https://docs.google.com/document/d/1f84eU88gv86TeDZS_nigL8oyKIM1z3uMr5RvzHCScjQ/edit?usp=sharing">Terms of Service</a> */}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} className="login-form-footer" >
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} style={{ alignItems: 'center', marginR: '0 auto' }}>

                <Form.Item>
                  <Button
                    className="my-button"
                    style={{ backgroundColor: "#609BE5", color: "#fff" }}
                    loading={isSigning}
                    htmlType="submit"
                  >
                    Sign up
                  </Button>
                </Form.Item>
                <Paragraph>
                  Already have account ? <Link to="/login"> Click Here</Link> to Login
                </Paragraph>
              </Col>
            </Row>
          </Form>
          <Modal
            centered
            width="auto"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="auth-model"
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div className="modal-header" style={{ background: '#fff' }} >
              <div className="modal-logo">
                <span className="auth-logo">
                  <Image
                    preview={false}
                    src={authLogo}
                    alt="Logo"
                  />
                </span>
                <Title level={4} style={{ textAlign: "center" }}>Canalytics.ai Web App</Title>
                <Title level={4} style={{ textAlign: "center" }}>Terms of Service</Title>
              </div>
            </div>
            <div className="modal-body" style={{ background: '#fff' }}>
              <TermCondition></TermCondition>
            </div>
          </Modal>
        </div>
        <div className="login-footer">
          <div className="footer-title">
            <Title level={3} style={{ color: '#fff', margin: '0', marginRight: '5px' }}> No Account?  </Title>
            <Title level={3} style={{ color: '#fff', margin: '0' }}> Sign Up In Seconds! </Title>
          </div>
          <div className="footerBtn">
            <Button
              className="signUpbtn"
            >
              <Link to="/register">  Sign up</Link>
            </Button>
            <Button
              className="loginUpbtn"
            >
              <Link to="/login"> Login</Link>
            </Button>
          </div>
        </div>
      </div>
    </section >
  );
}

export default SignUp;
