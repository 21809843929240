import { useState } from 'react';
import jwt_decode from "jwt-decode";

export default function useToken() {
    const getToken = () => {
        const tokenData = JSON.parse(localStorage.getItem('@tokenData'));
        return tokenData ? tokenData : null;
    };

    const checkTokenExpirey = () => {
        const userToken = localStorage.getItem('@token');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            decodedToken.exp = new Date(decodedToken.exp * 1000);
            if (decodedToken.exp < new Date()) {
                localStorage.clear()
            }
            return decodedToken.exp > new Date() ? userToken : null;

        } else {
            return null
        }
    };

    const decodeUserId = () => {
        const userToken = localStorage.getItem('@token');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            return decodedToken.user_id
        } else {
            return null
        }
    };

    const decodeUserType = () => {
        const userToken = localStorage.getItem('@token');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            return decodedToken.is_admin
        } else {
            return null
        }
    };

    const decodeAccountType = () => {
        const userToken = localStorage.getItem('@token');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            return (decodedToken.account_type === "SUBSCRIPTION" ? false : true)
        } else {
            return null
        }
    };


    const decodePayment = () => {
        const userToken = localStorage.getItem('@token');
        if (userToken) {
            const decodedToken = jwt_decode(userToken);
            return decodedToken?.is_payment_due
        } else {
            return null
        }
    };

    const decodeUserName = () => {
        const userData = JSON.parse(localStorage.getItem('@tokenData'));
        if (userData) {
            return userData.full_name
        } else {
            return null
        }
    };

    const [token, setToken] = useState(checkTokenExpirey());
    const [userLoginObject] = useState(getToken() ? getToken() : null);
    const [userId] = useState(decodeUserId());
    const [isAdmin, setIsAdmin] = useState(decodeUserType());
    const [userName, setUserName] = useState(decodeUserName());
    const [accountType, setAccountType] = useState(decodeAccountType());
    const [isPaymentDue, setIsPaymentDue] = useState(decodePayment());

    const saveToken = (userToken) => {
        localStorage.setItem('@tokenData', JSON.stringify(userToken.user));
        localStorage.setItem('@token', userToken.token);

        setToken(localStorage.getItem('@token'));
    };

    const removeToken = () => {
        localStorage.removeItem("@tokenData")
        localStorage.removeItem("@token")
        setToken(null)
        setIsAdmin(false)
    }

    return {
        saveLoginUser: saveToken,
        token,
        username: userName,
        getUserID: decodeUserId,
        userID: userId,
        isAdmin: isAdmin,
        accountType: accountType,
        isPaymentDue: isPaymentDue,
        removeToken,
        userLoginObject,
    }
}