import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../CheckOutForm';

import './style.css'
import Layout from '../../../components/layout/Layout';

const CheckOut = () => {
    const { REACT_APP_STRIPE_KEY } = process.env
    const location = useLocation()
    const { clientSecret, id, packages, price, amount } = useParams()
    console.log(clientSecret)
    const dispatch = useDispatch()
    const [stripePromise, setStripePromise] = useState(null);
    const [options, setOption] = useState('');

    useEffect(() => {
        setOption({
            // passing the client secret obtained from the server
            clientSecret: clientSecret
        })
        setStripePromise(loadStripe(REACT_APP_STRIPE_KEY))
    }, [])

    return (
        <Layout title="New Analysis Order">
            <div className="wn-page wn-payment mx-auto">
                <div className='px-4 containerCard'>
                    <div className="App">
                        {stripePromise && clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm id={id} packages={packages} price={price} amount={amount} />
                            </Elements>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )

}


export default CheckOut