export const CustomerType = [
    { 'Key': 1, 'name': 'Cannabis Brand' },
    { 'Key': 2, 'name': 'Cannabis Lab' },
    { 'Key': 3, 'name': 'Breeder' },
    { 'Key': 4, 'name': 'Phenohunting' },
    { 'Key': 5, 'name': 'Cultivator' },
    { 'Key': 6, 'name': 'Dispensary' },
    { 'Key': 7, 'name': 'Research' },
    { 'Key': 8, 'name': 'Government' },

]

export const packageValue = [
    { id: 0, name: 'Adult Use Basic' },
    { id: 1, name: 'Therapeutic Basic' },
    { id: 2, name: 'Therapeutic & Adult Use Basic' },
    { id: 3, name: 'Therapeutic & Adult Use Advanced' },
    { id: 4, name: 'Therapeutic & Adult Use Complete' },
    { id: 6, name: '6 Adult Use' },
    { id: 7, name: '6 Therapeutic' },
    { id: 8, name: 'Combined Analysis' }
]

export const packageValueNew = [
    { id: 6, name: '6 Adult Use' },
    { id: 7, name: '6 Therapeutic' },
    { id: 8, name: 'Combined Analysis' }
]