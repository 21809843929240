// Libraries
import React, { useEffect } from "react";

// Components
import Layout from "../../../components/layout/Layout";
import AddNewUser from "../../../components/admin/addnewuserform/AddNewUser";

// Style
import "./index.css";
import { useDispatch } from "react-redux";
import useToken from "../../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import interceptor from "../../../shared/interceptor";
import { useState } from "react";

function MyAccount() {

  const dispatch = useDispatch()
  const { token, userID } = useToken()
  const naviagte = useNavigate();
  const [item, setItems] = useState();

  return (
    <Layout title="My Account">
      <div className="my-account">
        <AddNewUser showCheckbox={false} showSaveButton={true} />
      </div>
    </Layout>
  );
}
export default MyAccount;
