// Libraries
import React from "react";
import { Typography, Dropdown, Space, Image } from "antd";

// Components
import { DownOutlined } from "@ant-design/icons";

// Images
import admin from '../../assets/images/canalyticsLogo.png';

// Style
import "./style.css";
import { useSelector } from "react-redux";
import useToken from "../../hooks/useToken";

const { Title } = Typography;
export default function Header({ title }) {

  const { username } = useToken()
  const items = [
    {
      key: "1",
      label: (
        <a href="#">
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href="#">
          2st menu item
        </a>
      ),
    },
  ];
  return (
    <header className="header">
      <div className="navbar-left">
        <Title>{title}</Title>
      </div>
      <div className="navbar-right">
        {/* <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
          arrow
        > */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {username}  <Image preview={false} src={admin} width={40} height={40} />
              {/* <DownOutlined /> */}
            </Space>
          </a>
        {/* </Dropdown> */}
      </div>
    </header>
  );
}
