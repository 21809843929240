// Libraries
import React from "react";
import { Col, Row, Typography, Button } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import MyButton from "../../../components/mybutton/MyButton";
import AddNewUser from "../../../components/admin/addnewuserform/AddNewUser";
import CustomPricing from "../../../components/admin/custompricing/CustomPricing";
import PricingCard from "../../../components/admin/pricingcard/PricingCard";
import PricingCardData from "../../../data/admin/PricingCardData";
import SubscriptionPricingListData from "../../../data/admin/SubscriptionPricingListData";

// Styles
import "./index.css";

const { Title } = Typography;
function Overview() {
  return (
    <Layout title="Admin Dashboard">
      <AddNewUser showCheckbox={true} />
      <div className="add-user-btn" style={{ textAlign: "center" }}>
        <Button
          className="my-button"
          style={{
            backgroundColor: "#EA6558",
            color: "#fff",
            width: "initial",
            display: "inline-block",
          }}
          htmlType="submit"
        >
          Temp Deactivation (Until Bill Paid)
        </Button>
      </div>
      <div className="client-pricing-title">
        <Title level={1}>Select New User’s Subscription Plan</Title>
      </div>
      <div className="scroll">
        <div className="user-pricing-card custom-pricing">
          <Row>
            <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={8}>
              <CustomPricing
                title="Define Subscription Pricing"
                data={SubscriptionPricingListData}
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={16}>
              <div className="pricing-content user-subs-plan">
                {PricingCardData.map((item, index) => (
                  <PricingCard
                    background={item.background}
                    item={item}
                    key={index}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Button
          className="my-button"
          style={{
            backgroundColor: "#609BE5",
            color: "#fff",
            width: "initial",
            display: "inline-block",
          }}
          htmlType="submit"
        >
          Add User (CUSTOM Pricing)
        </Button>
      </div>
    </Layout>
  );
}

export default Overview;
