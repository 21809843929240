// Libraries
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";

import './style.css';
import { Spin } from "antd";

function ColumnChart({ item, loading, strain_name }) {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (item) {
      setState({
        series:
          [
            {
              data: item?.bar_graph.value || [],
              //[21, 22, 10, 28, 16, 21, 13, 30].slice(0, 6), // Show only 6 columns
            },
          ],
        breakpoint: 450,
        options: {
          chart: {
            type: "bar",
            events: {
              click: function (chart, w, e) {
                // console.log(chart, w, e)
              },
            },
            toolbar: {
              show: false,
            }
          },
          grid: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              },
              title: {
                formatter: function (seriesName) {
                  return ''
                }
              }
            }
          },
          plotOptions: {
            bar: {
              columnWidth: "12%",
              distributed: false,
              borderRadius: 10,
              borderRadiusApplication: 'end',
              borderRadiusWhenStacked: 'last',
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 100,
                    color: "#558AC9",
                  },
                ],
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },

          xaxis: {
            categories: item?.bar_graph.categories || [],
            tickPlacement: 'between',
            width: '50px',
            labels: {
              rotate: 310,
              hideOverlappingLabels: false,
              height: "100%",
              trim: false,
              style: {
                colors: ["#558AC9"],
                fontSize: 11,
                height: 100,
              },
            },
          },
          yaxis: {
            logBase: 10,
            floating: false,
            min: 0,
            max: 10,
            labels: {
              formatter: (value) => {
                return value.toFixed(0)
              },
            }
          },

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: item?.bar_graph.value.length < 20 ? '100%' : item?.bar_graph.value.length * 20,
                  height: 300,
                },
                xaxis: {
                 
                  labels: {
                    rotate: 310,
                    height: "100%",
                    style: {
                      colors: ["#558AC9"],
                      fontSize: 11,
                      height: 100,
                    },
                  },
                },
              },
            },
            {
              breakpoint: 770,
              options: {
                xaxis: {
                 
                  labels: {
                    rotate: 310,
                    height: "100%",
                    style: {
                      colors: ["#558AC9"],
                      fontSize: 13,
                      height: 100,
                    },
                  },
                },
              },
            },
          ],
        },
      })
    }
  }, [item])

  return (
    <div id="chart" className="bar-cahrt">
      {!loading ? <>

        {state && <ReactApexChart
          options={state?.options}
          series={state?.series}
          type="bar"
          height={300}
          width={state?.chart?.width}
          loading={loading}
        />
        }
      </> :
        <Spin></Spin>
      }
    </div>
  );
}

export default ColumnChart;

