import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./actions/user/usre.reducer";


const store = configureStore({
	reducer: {
		userReducer
	},

	// middleware:[...getDefaultMiddleware,loggerMidleWare]
	// The thunk middleware was automatically added
	// https://github.com/reduxjs/redux-thunk
});

export default store;

