// Libraries
import React, { useState } from "react";
import { Typography, List, Tag, Button } from "antd";
import { Link, useParams } from "react-router-dom";

// Components
import DownloadPdfListData from "../../data/DownloadPdfListData";
import { Pdf } from "../../icons/Icons";
import MyButton from "../mybutton/MyButton";

// Style
import "./style.css";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import { saveAs } from 'file-saver';
const { Title, Text } = Typography;
function PdfFileLists(props) {
  const { items, count, onRemove, title } = props
  const list = items?.user_order_files ? items?.user_order_files : items

  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const { token } = useToken()
  const downloadPdfList = async () => {
    setLoading(true)
    await userActions.getOrdersListByOrderId(token, id).then(res => {
      setLoading(false)
      if (res) {
        saveAs(res)
      }

    })
  }

  return (
    <div className="pdf-list-data">
      <List
        bordered
        footer={
          props.showFooter ? (
            <Button
              className="my-button"
              style={{ color: "#787878", padding: '0' }}
              htmlType="submit"
            >
              {props.text}
            </Button>
          ) : null
        }
      >
        <div className="list-header">
          <Title level={5}>{list?.length} {title ? title : 'PDFs Uploaded'}</Title>
          {props.showTag ? (
            <Button
              className="my-button"
              onClick={downloadPdfList}
              loading={loading}
              style={{
                backgroundColor: "#F1F1F5",
                color: "#696974",
                width: "initial",
                display: "inline-block",
              }}

            >
              Download All Order PDFs
            </Button>
          ) : null}
        </div>
        {
          list && list.length > 0 && list.map((item, index) => (
            <List.Item key={index}>
              {index + 1}
              <Pdf color="#1377C9" />
              <List.Item.Meta
                title={<Link to="#">{item.name}</Link>}
                description={
                  props.showButton ? (
                    <Button
                      className="my-button"
                      style={{
                        backgroundColor: "#E56080",
                        color: "#fff",
                        width: "initial",
                        display: "inline-block",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                      }}
                      htmlType="submit"
                      onClick={() => {
                        onRemove(item.id)
                      }}
                    >
                      Remove
                    </Button>
                  ) : null
                }
              />
            </List.Item>
          ))
        }
      </List >
    </div >
  );
}

export default PdfFileLists;
