import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Upload, message, Typography, Button, Modal, Row, Col, Progress, Spin } from "antd";
import { UploadFile } from "../../icons/Icons";
import "./style.css";
import useToken from "../../hooks/useToken";
import { useParams } from "react-router-dom";
import { error, success } from "../Notifications";
import axios from "axios";
const { Text, Title } = Typography;
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

var upload = false;

function UploadImage({ uploadedFile, credit, availCredit, accept }) {
  const { token, isAdmin, } = useToken()
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0)
  const [fileUrl, setFileUrl] = useState();
  const [message, SetMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [call, setCall] = useState(new AbortController());
  const [uploading, setUploading] = useState(false);

  const { id } = useParams()
  var creditChk = 0
  const handleChange = (info) => {

    // SetMessage(null)
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // uploadImage(info.file,)
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setLoading(false);
      //   setImageUrl(url);
      // });
    }
  };
  const beforeUpload = (file) => {
    // debugger
    // SetMessage(null)
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng && !isAdmin) {
      message.error("You can only upload PDF files!");
      return false
    }
    // if (credit == 0) {
    //   message.error(`You can only upload ${availCredit}PDF file!`);
    //   return false
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error("File must smaller than 2MB!");
    // }
    return true
    // return isJpgOrPng && isLt2M;
  };
  const uploadImage = (item) => {
    debugger
    if (!upload) {
      setProgress(0)
      upload = true
      if (credit != creditChk) {
        creditChk = creditChk + 1
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.Accept = 'application/json';
        var formdata = new FormData();
        item.fileList.forEach(element => {
          formdata.append("file", element.originFileObj)
        });

        formdata.append("order", id)
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow',

        };

        let url = isAdmin ? 'admin/order/upload-order-file/' : 'upload-file-multiple/'
        axios.post(process.env.REACT_APP_BASE_URL + url, formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            onUploadProgress: data => {
              setProgress(Math.round((100 * data.loaded) / data.total))
            },
            signal: call?.signal
          })
          .then(res => {
            debugger
            upload=false
            setUploading(false)
            setLoading(false)
            if (!isAdmin) {
              if (res.data.success_files.length > 0) {
                res.data.success_files.forEach(x => {
                  debugger
                  uploadedFile({ id: x.data.id, name: x.file }, true)
                }
                )
              }
              if (res.data.failed_files.length > 0) {
                res.data.failed_files.forEach(x =>
                  uploadedFile({ id: x.file, name: x.file }, false)
                )
                SetMessage('Some COAs missing required cannabinoid and terpene data were excluded from analysis.')
                error("topRight", 'COAs that couldn’t be added', 'Requires terpene & cannabinoid data')
              }
            } else {
              uploadedFile(res.data.data, false)
            }

          }).catch(err => {
            setLoading(false)

          })
      }
    }

  }

  const newAbortSignal = (timeoutMs) => {
    debugger
    if (call.signal) {
      call.abort(); // This will abort the request automatically
      upload = false
    }
    setTimeout(() => {
      setCall(new AbortController())
    }, 1000);
  }


  const uploadButton = (
    <div style={{
      padding: "30px  0 10px",
    }}>
      {loading ? <LoadingOutlined /> : <UploadFile color="#939393" />}
      <div

        className="upload-box"
      >
        <Text> {credit > 0 ? 'Drag & Drop Files Here' : 'You  dont have credit'} </Text>
        {credit > 0 &&
          <>
            <Text>Or</Text>
            <Button loading={loading} >  Browse Files</Button>
            {message && <> <Text style={{ color: 'red', fontSize: '15px', margin: '15px auto 5px', width: '80%', textAlign: 'center' }}>
              Some COAs missing required cannabinoid and terpene data were excluded from analysis.
            </Text>
            </>}

          </>}
      </div>
    </div>
  );
  return (
    <>
      <Modal
        centered
        width="100%"
        open={loading}
        closable={false}
        style={{ maxWidth: "500px" }}
        footer={null}
      >
        <Row gutter={16} style={{ paddingTop: '15px' }}>
          <Col className="gutter-row upload-icon" xl={5} lg={5} md={5} sm={5} xs={5} style={{ height: 'fit-content' }}>
            <UploadFile color="#fff" />
            <div className="loader">
            </div>
          </Col>
          <Col className="gutter-row" xl={19} lg={19} md={19} sm={19} xs={19}>
            <div style={{ display: 'flex' }} >
              <Title level={2} style={{ margin: '0px', color: '#3498db' }}>{progress}%</Title>
              <Title level={5} style={{ margin: '10px 0 0 10px', color: '#00000085' }}>Checking COAs for Required Data</Title>
            </div>
            {loading && <Progress percent={progress} strokeColor={'#3498db'} />}
          </Col>
        </Row>

        <Row gutter={16} style={{ paddingTop: '15px' }}>
          <Col className="gutter-row upload-icon" xl={24} lg={24} md={24} sm={24} xs={24} >
            <Button type="" className="modalbtn" onClick={newAbortSignal}>  Cancel</Button>
          </Col>
        </Row>

      </Modal>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        fileList={[]}
        // action={uploadImage}
        beforeUpload={beforeUpload}
        multiple
        disabled={credit < 1}
        maxCount={availCredit}
        accept={accept}
        onChange={uploadImage}
      >
        {fileUrl ? (
          <img
            src={fileUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) :
          uploadButton
        }

      </Upload>
    </>

  );
}

export default UploadImage;
