import interceptor from "../shared/interceptor";


export const loginUser = (model) => {
    return new Promise((resolve, reject) => {
        interceptor.axiosLogin("Post", "login/", model, null).then(res => {

            resolve(res);
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            reject(error?.response);
        })

    });
};


export const SignUpUser = (model) => {
    return new Promise((resolve, reject) => {
        interceptor.axiosLogin("Post", "sign-up-user/", model, null).then(res => {

            resolve(res);
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            reject(error?.response);
        })

    });
};

export const resetPassword = (model) => {
    return new Promise((resolve, reject) => {
        interceptor.axiosLogin("Post", "reset-password/", model).then(res => {

            resolve(res);
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            reject(error?.response);
        })

    });
};
export const forgotPassword = (model) => {
    return new Promise((resolve, reject) => {
        interceptor.axiosLogin("Post", "forgot-password/", model).then(res => {

            resolve(res);
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            reject(error?.response);
        })

    });
};