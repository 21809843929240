// Libraries
import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Form, Input, Button, Card, List } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../hooks/useToken";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import interceptor from "../../../shared/interceptor";

// Styles
import "./index.css";
import Pricing from "../../../components/admin/pricing/Pricing";
import CustomPricing from "../../../components/admin/custompricing/CustomPricing";
import PricingCard from "../../../components/admin/pricingcard/PricingCard";
import PricingCardData, { PricingCardRegularData } from "../../../data/admin/PricingCardData";
import ClientPricingListData, { ClientPricingListDataForAdd } from "../../../data/admin/ClientPricingListData";
import { success } from "../../../components/Notifications";
import { SubscriptionPricingListDataAdd } from "../../../data/admin/SubscriptionPricingListData";
const { Title, Text } = Typography;



const status = [{ id: 'RECREATIONAL_BASIC', name: 'Adult Use (Basic)' },
{ id: 'MEDICAL_BASIC', name: 'Therapeutic (Basic)' },
{ id: 'MEDICAL_AND_RECREATIONAL_BASIC', name: 'Therapeutic & Adult Use (Basic)' },
{ id: 'MEDICAL_AND_RECREATIONAL_ADVANCED', name: 'Therapeutic & Adult Use (Advanced)' },
{ id: 'MEDICAL_AND_RECREATIONAL_COMPLETE', name: 'Therapeutic & Adult Use (Complete)' },
]

function Profile() {
    const [form] = Form.useForm();
    const [PricingCardObject, setPricingCardObject] = useState(PricingCardData)
    const [PricingCardSubObject, setPricingCardSubObject] = useState(PricingCardRegularData)
    const [item, setItem] = useState(null)
    const [subItem, setSubItem] = useState(null)
    const [Regloading, setRegloading] = useState(false)
    const [regPricingData, setRegPricingData] = useState(null)
    const [subPricingData, setSubPricingData] = useState(null)
    const [credit, setCredit] = useState(null)
    const { token, userID, accountType, isAdmin, isPaymentDue } = useToken()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(null)
    const userList = useSelector((state) => state.userReducer.userList)
    const naviagte = useNavigate();

    const onFinish = (values) => {
        console.log('Success:', values);
        values.id = userID
        interceptor.axiosPost('Post', 'update-profile/', values, token).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                naviagte("/main-page");

            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };

    useEffect(() => {
        if (!item) {
            setLoading(true)
            userActions.getprofile(token, userID).then(user => {
                setLoading(false)
                setItem(user)
                form.setFieldsValue(user)
            })
        }

        if (!regPricingData) {
            getRegularPrice()
        }
        if (!subPricingData) {
            getSubscriptionPrice()
        }

    })



    const getSubscriptionID = () => {
        setLoading(true)
        interceptor.axiosGet('GET', 'create-stripe-intent-for-subscription-credits/', token).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                let CLIENT_SECRET = res.data.client_secret;
                naviagte(`/strip-check-out/${CLIENT_SECRET}/${res.data.id}/${item?.package_name}/${item?.custom_price_of_package}`);
            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })
    }

    const saveRegularCredit = () => {
        setRegloading(true)
        interceptor.axiosPost('Post', 'user-pricing/',
            {
                pricing_type: 0,
                pricing_data: JSON.stringify(regPricingData)
            }
            , token).then((res) => {
                setRegloading(false)
                if (res.status === 200) {
                    success('topRight', 'Save Successfully')
                }
                else {
                    console.log("error getting user : ", res)
                }
            }).catch((err) => {
                return err
            })
    }

    const saveSubscriptionCredit = () => {
        setRegloading(true)
        interceptor.axiosPost('Post', 'user-pricing/', {
            pricing_type: 1,
            pricing_data: JSON.stringify(subPricingData)
        }, token).then((res) => {
            setRegloading(false)
            if (res.status === 200) {
                success('topRight', 'Save Successfully')
            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })
    }


    const getRegularPrice = () => {
        setRegloading(true)
        interceptor.axiosGet('Get', 'get-user-pricing/?pricing_type=0', token).then((res) => {
            setRegloading(false)
            if (res.status === 200) {
                console.log((res.data.pricing_data))
                setRegPricingData(JSON.parse(res.data.pricing_data))
            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })
    }


    const getSubscriptionPrice = () => {
        setRegloading(true)
        interceptor.axiosGet('Get', 'get-user-pricing/?pricing_type=1', token).then((res) => {
            setRegloading(false)
            if (res.status === 200) {
                console.log((res.data.pricing_data))
                setSubPricingData(JSON.parse(res.data.pricing_data))
            }
            else {
                console.log("error getting user : ", res)
            }
        }).catch((err) => {
            return err
        })
    }


    return (
        <Layout title="Account Information">


            {isPaymentDue && <Title level={4}>Please make a payment first to enjoy seamless services.</Title>}
            {!isAdmin && !accountType && <Row gutter={24}>
                <Col className="gutter-row card-space !item.enable? ai-inactive: '' " xs={24} sm={24} md={12} lg={12} xl={6}>
                    <div className="subscription-cards">
                        <>
                            <Card
                                key={item?.package_name}
                                style={{
                                    height: "100%",
                                    display: "gird !important",
                                    justifyContent: "center !important",
                                    textAlign: 'center'
                                }}
                            >
                                <Title level={4}>Package: {status.find(x => x.id == item?.package_name)?.name}</Title>
                                <div className="card-price-meta">
                                    {/* <Text>{item.currency}</Text> */}
                                    <Title level={5}>Price: ${item?.custom_price_of_package}</Title>
                                </div>
                                {item?.subscription_expiry_date && new Date(item.subscription_expiry_date) < new Date() && <div style={{ marginBottom: "15px", marginTop: "5px" }}>
                                    Expired at: <span className="card-subs-date">
                                        {item?.subscription_expiry_date}
                                    </span>
                                </div>}
                                {item?.subscription_expiry_date && new Date(item.subscription_expiry_date) > new Date() && <div style={{ marginBottom: "15px", marginTop: "5px" }}>
                                    Expiry: <span className="card-subs-date">
                                        {item?.subscription_expiry_date}
                                    </span>
                                </div>}
                                <div className="card-price-meta-btn">
                                    <Button
                                        type="primary"
                                        onClick={() => getSubscriptionID()}
                                        loading={loading}
                                        style={{ backgroundColor: "#F7EA82", color: "#151517" }}
                                        disabled={item?.subscription_expiry_date && new Date(item.subscription_expiry_date) > new Date()}
                                    >
                                        {item?.subscription_expiry_date && new Date(item.subscription_expiry_date) < new Date() ? "Renew" : item?.subscription_expiry_date && new Date(item.subscription_expiry_date) > new Date() ? 'Subscribed' : 'Subscribe'}
                                    </Button>
                                </div>
                            </Card>
                        </>
                    </div>
                </Col>
            </Row>}

            {!isPaymentDue && <div className="add-new-user">
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={16} lg={12} xl={8}>
                        <Title level={5}>User Profile</Title>
                        <Form
                            name="profile"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Email',
                                    },
                                ]}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item label="Comapny Name"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Company Name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Password',
                                    },
                                ]}>
                                <Input type="password" />
                            </Form.Item>
                            <Form.Item label="Phone"
                                name="phone_number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter PhoneNumber',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Full Name"
                                name="full_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Full Name',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Address"
                                name="location"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Address',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <div style={{ marginTop: "30px" }}>
                                <Button
                                    className="my-button"
                                    style={{
                                        backgroundColor: "#609BE5",
                                        color: "#fff",
                                        width: "initial",
                                        display: "inline-block",
                                    }}
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
            }

            {isAdmin && <div className="custom-pricing">
                < Row align="top" >
                    <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={7}>
                        <CustomPricing
                            title="Client Custom Pricing"
                            data={ClientPricingListDataForAdd}
                            global={false}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={17}>
                        <div className="pricing-content">
                            {regPricingData?.map((item, index) => (
                                <List className="pricing-title">
                                    <List.Item style={{ background: item.background }}>
                                        <Typography.Text>{item.topTitle}</Typography.Text>
                                        <Typography.Text>{item.bottomTitle}</Typography.Text>
                                    </List.Item>
                                    {item.items.map((dollar, index2) => (

                                        <>
                                            {!dollar.custom && <List.Item
                                                id={dollar.key}
                                                key={index2}
                                                className={dollar.checek ? "selected" : ""}
                                            // onClick={() => changeValue(dollar)}
                                            >

                                                <Input
                                                    placeholder="0"
                                                    id={dollar?.key + '_input' + index}
                                                    className={`customvalue ` + dollar?.key}
                                                    style={{ color: "black" }}
                                                    value={dollar.value}
                                                    onChange={(e) => {
                                                        regPricingData[index].items[index2].value = e.target.value
                                                        setPricingCardObject(oldArray => [...oldArray, regPricingData])
                                                    }}
                                                />

                                            </List.Item>
                                            }
                                        </>

                                    ))}
                                    {/* </>
                                   )}
                                 </Form.List> */}
                                </List>
                            ))}
                        </div>
                    </Col>
                </Row >
                <div style={{ marginTop: "30px", textAlign: 'right' }}>
                    <Button
                        className="my-button"
                        style={{
                            backgroundColor: "#609BE5",
                            color: "#fff",
                            width: "initial",
                            display: "inline-block",
                        }}
                        loading={Regloading}
                        onClick={saveRegularCredit}
                    >
                        Save Regular User Credit
                    </Button>
                </div>
            </div>}

            {isAdmin && <div className="custom-pricing">
                < Row align="top" >
                    <Col className="gutter-row" xs={24} sm={16} md={12} lg={12} xl={7}>
                        <CustomPricing
                            title="Define Subscription Pricing"
                            data={SubscriptionPricingListDataAdd}
                            global={false}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={17}>
                        <div className="pricing-content">
                            {subPricingData?.map((item, index) => (
                                <List className="pricing-title">
                                    <List.Item style={{ background: item.background }}>
                                        <Typography.Text>{item.topTitle}</Typography.Text>
                                        <Typography.Text>{item.bottomTitle}</Typography.Text>
                                    </List.Item>
                                    {item.items.map((dollar, index2) => (

                                        <>
                                            {!dollar.custom && <List.Item
                                                id={dollar.key}
                                                key={index2}
                                                className={dollar.checek ? "selected" : ""}
                                            // onClick={() => changeValue(dollar)}
                                            >

                                                <Input
                                                    placeholder="0"
                                                    id={dollar?.key + '_input' + index}
                                                    className={`customvalue ` + dollar?.key}
                                                    style={{ color: "black" }}
                                                    value={dollar.value}
                                                    onChange={(e) => {
                                                        subPricingData[index].items[index2].value = e.target.value
                                                        console.log(subPricingData)
                                                        setPricingCardSubObject(oldArray => [...oldArray, subPricingData])
                                                    }}
                                                />

                                            </List.Item>
                                            }
                                        </>

                                    ))}
                                    {/* </>
                                   )}
                                 </Form.List> */}
                                </List>
                            ))}
                        </div>
                    </Col>
                </Row >
                <div style={{ marginTop: "30px", textAlign: 'right' }}>
                    <Button
                        className="my-button"
                        style={{
                            backgroundColor: "#609BE5",
                            color: "#fff",
                            width: "initial",
                            display: "inline-block",
                        }}
                        loading={Regloading}
                        onClick={saveSubscriptionCredit}
                    >
                        Save Subscription User Credit
                    </Button>
                </div>
            </div>
            }
        </Layout>
    );
}

export default Profile;
