const actionTypes = {
    signUp: 'signUp',
    singIn: 'singIn',
    users: 'users',
    user: 'user',
    ordersPending: 'ordersPending',
    orderProcessing: 'orderProcessing',
    ordersDelivered: 'ordersDelivered',
    userOrderList: 'userOrderList',
    userCredits:"userCredits",
    clearChahce:"clearChahce"
}
export default actionTypes
