const ClientPricingListData = [
  {
    item: "Single PDF (COA) Analysis",
  },
  {
    item: "Custom Pricing (Single PDF)",
  },
  {
    item: "Prepay 500 Analysis Credits",
  },
  {
    item: "Custom Pricing (500 Credits)",
  },

  {
    item: "Prepay 1,000 Analysis Credits",
  },
  {
    item: "Custom Pricing (1,000 Credits)",
  },
];

export default ClientPricingListData;


export const ClientPricingListDataForAdd = [
  {
    item: "Single PDF (COA) Analysis",
  },
  {
    item: "Prepay 500 Analysis Credits",
  },

  {
    item: "Prepay 1,000 Analysis Credits",
  },
];

