import React from 'react';
import { useStripe, useElements, CardElement, LinkAuthenticationElement, AddressElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import interceptor from '../../../shared/interceptor';
import { error, success } from '../../../components/Notifications';
import { useNavigate } from 'react-router-dom';
import useToken from '../../../hooks/useToken';
import stripLogo from "../../../assets/images/stripe.png";
import stripeSealed from "../../../assets/images/stripeSealed.webp";
import { Typography, Card, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import actionTypes from '../../../redux-toolkit/actions/user/user.action.type';
const { Title, Text } = Typography;

const packageValue = [
    { id: 'RECREATIONAL_BASIC', name: 'Adult Use (Basic)' },
    { id: 'MEDICAL_BASIC', name: 'Therapeutic (Basic)' },
    { id: 'MEDICAL_AND_RECREATIONAL_BASIC', name: 'Therapeutic & Adult Use (Basic)' },
    { id: 'MEDICAL_AND_RECREATIONAL_ADVANCED', name: 'Therapeutic & Adult Use (Advanced)' },
    { id: 'MEDICAL_AND_RECREATIONAL_COMPLETE', name: 'Therapeutic & Adult Use (Complete)' },
]
const StripeCardForm = ({ id, packages, price, amount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(null)
    const naviagte = useNavigate();
    const { token, removeToken, saveLoginUser } = useToken()
    const dispatch = useDispatch();
    const userToken = token
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            // Create a token from the card element
            const { token } = await stripe.createToken(cardElement);
            console.log(userToken)
            interceptor.axiosPost('POST', 'attach-card/', {
                "token_id": token.id
            }, userToken).then(async (res) => {
                setLoading(false)
                if (res?.data?.status === 200) {
                    removeToken();
                    await saveLoginUser(res.data.data);
                    await dispatch({ type: actionTypes.singIn, payload: res.data.data });
                    success('topRight', res.data.message)
                    naviagte('/profile');
                }
                else {
                    error('topRight', res.data.message)
                }
            }).catch((err) => {
                return err
            })

            console.log('Stripe Token:', token);
        } catch (error) {
            console.error('Error creating token:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ textAlign: 'center' }}>
                <img
                    src={stripLogo}
                    alt="Stripe Seal"
                    style={{ width: '150px', marginBottom: '20px' }}
                />
            </div>
            <Row gutter={24} >

                {packages != "null" && price != "null" &&
                    <Col className="gutter-row" xl={24} lg={24} md={24} sm={24} xs={24}>

                        <div >
                            <Title level={5}>Package : {packageValue.find(x => x.id == packages)?.name}</Title>
                            <Title level={5}>Monthly Payment : ${price}</Title>
                        </div>
                    </Col>
                }
            </Row >
            <LinkAuthenticationElement
                options={{
                    defaultValues: {
                        email: '',
                    },
                    validation: {
                        email: {
                            required: 'always',
                        },
                    },
                }} />
            <CardElement className='StripeElementa' style={{ paddingTop: '15px' }}
                options={{
                    iconStyle: "solid",
                    style: {
                        base: {
                            border: '1px solid',
                            paddingTop: '15px',
                            fontWeight: '500',
                            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            lineHeight: '1',
                            padding: '5px',
                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            '::placeholder': {
                                color: '#87BBFD',
                            },
                        },
                    }
                }}
            />
            <button className="my-button"
                style={{
                    backgroundColor: "#609BE5",
                    color: "#fff",
                    width: "initial",
                    display: "inline-block",
                    marginTop: "15px",
                    cursor: "pointer"

                }}
                disabled={!stripe} id="submit">
                <span > {loading ? 'Processing ...' : 'Submit'}  </span>
            </button>
            <img
                src={stripeSealed}
                className='stripSeal'
                alt="Stripe Seal"
            />
        </form>
    );
};

export default StripeCardForm;
