// Libraries
import React, { useState } from "react";
import { Typography, Image, Checkbox, Form, Input, Button, Alert } from "antd";

// Components
import authLogo from "../../assets/images/logotm.png";
import MyButton from "../../components/mybutton/MyButton";
import { useShareDispatch } from "../../shared";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./index.css";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../apis/auth";
import useToken from "../../hooks/useToken";
import userActions from "../../redux-toolkit/actions/user/user.action";
import actionTypes from "../../redux-toolkit/actions/user/user.action.type";
import { success } from "../../components/Notifications";
import GoogleDocsViewer from "react-google-docs-viewer";

const { Title, Paragraph } = Typography;

function TermCondition() {



  return (
    <div className="login-form" style={{ height: "45vh" , padding: '0px 50px 0',overflowY:'auto'}}>
      <div className="">
        <p dir="ltr" className="h-1"><span className="h-8">1) Introduction</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">1.1 Acceptance of Terms</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By accessing or using the Canalytics Web App (&quot;the App&quot;), you agree to be bound by these Terms of Service (&quot;the Agreement&quot;). This Agreement sets forth the legally binding terms and conditions that govern your use of the App. If you do not agree to these terms, you may not use the App.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">1.2 Description of Services</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">1.2.1 Cannabis Effect Analysis:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">a. Description:&nbsp;</span><span className="h-4">Canalytics &ldquo;The Company&rdquo; provides cannabis effect analysis to clients for a wide range of high-demand effects. Users can reach out to our team to sign up for an account. Once their account is created users can start a new analysis order by selecting a tier of analysis depth and then uploading their Certificate of Analysis (COA) files then paying and submitting their order. The Company utilizes its two decades of expertise, robust scholarly study database and proprietary algorithms to analyze the effects of the cannabinoid and terpene profiles of the provided COAs.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">1.2.2 Interactive Analysis Reports:&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">a. Access and Use:&nbsp;</span><span className="h-4">Users gain access to an intuitive and interactive dashboard interface, which presents a wide array of analysis reports and analytical tools. This service is designed to equip Users with the means to unearth significant insights that can benefit their cannabis-related projects and enterprises.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">b. Functionality:&nbsp;</span><span className="h-4">Through the dashboard, Users can access visually engaging and actionable depictions of effects for multiple COAs concurrently, ranging from hundreds to thousands. This function facilitates efficient, informed, and data-driven decision-making, potentially leading to enhanced outcomes.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">c. Overview:&nbsp;</span><span className="h-4">The App provides analysis services for lab-tested Certificate of Analysis (COA) reports of cannabis products. The analysis focuses on the therapeutic and recreational effects of cannabis products based on their cannabinoid and terpene profiles. The App serves as a platform for users to access and utilize the analysis services provided by the Company. The analysis results and reports generated by the App are intended to provide users with insights and information regarding cannabis profiles, formulas, expressions and products.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">2.1 Creating a Canalytics WebApp Account</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">To access the App and its features, users must contact the Canalytics team and create a web app account. You agree to provide accurate, current, and complete information during the registration process with our staff and to update this information promptly from your My Accounts section if there are any changes. You acknowledge that the information you provide during registration will be used in accordance with our Privacy Policy.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">2.2 Account Security</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You are solely responsible for maintaining the confidentiality and security of your account credentials, including your username and password. You agree to take all necessary precautions to prevent unauthorized access to your account. You must promptly notify us if you become aware of any unauthorized use of your account or any other breach of security. The Company shall not be liable for any loss or damage arising from your failure to comply with these security obligations.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You understand and acknowledge that the Company reserves the right to suspend or terminate your account if we suspect any unauthorized access or use of your account, or if we have reasonable grounds to believe that the information you provided during registration is inaccurate, incomplete, or fraudulent. The Company may also refuse any future use of the App by you.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You agree that you will not share your account credentials with any third party or allow any other individual or entity to access or use your account. You are solely responsible for any activities that occur under your account, whether or not authorized by you.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">2.3 Additional Information&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that the &quot;Description of Services&quot; section provided above is intended as a general overview of the services offered by Canalytics. Clients are advised to review and discuss the specific details and terms within the context of their contractual agreement with the Company to ensure a comprehensive understanding of the scope of services provided.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">3) Use of the App</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">3.1 Compliance with Laws</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You agree to use the App in compliance with all applicable laws, regulations, and industry standards. You are solely responsible for ensuring that your use of the App is lawful in your jurisdiction. You understand that the legality of cannabis-related activities may vary from one jurisdiction to another, and it is your responsibility to understand and comply with the laws and regulations that apply to you.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">3.2 Prohibited Activities</span></p>
        <p><br /></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">While using the App, you agree not to engage in any of the following activities:</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Violating any applicable laws, regulations, or third-party rights, including intellectual property rights.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Uploading, transmitting, or distributing any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Impersonating any person or entity or falsely representing your affiliation with any person or entity.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Interfering with or disrupting the operation of the App or the servers or networks connected to the App, including but not limited to introducing viruses, worms, or other malicious code.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Attempting to gain unauthorized access to the App or any other user&apos;s account or to any portion of the App&apos;s systems or networks.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Engaging in any activity that could damage, disable, overburden, or impair the infrastructure of the App or interfere with other users&apos; access to the App.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Using the App for any commercial or promotional purposes without obtaining our prior written consent.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">3.3 Intellectual Property Rights</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The App and its contents, including but not limited to text, graphics, logos, and software, are protected by intellectual property laws, including copyright and trademark laws. You acknowledge and agree that all intellectual property rights in the App and its contents belong to the Company or its licensors. Except as expressly authorized by the Company in writing, you agree not to modify, reproduce, distribute, create derivative works of, publicly display, or publicly perform any materials or content obtained from the App.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">3.4 Reposting and Use of Canalytics Analysis Data</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>1. Attribution Requirement</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">Any Client, or a third-party organization authorized by the Client (hereinafter collectively referred to as the "Reposting Party"), that wishes to use, display, or repost any analysis results, data, or other content (hereinafter collectively referred to as the "Analysis Data") provided by Canalytics, must clearly attribute such Analysis Data to Canalytics. The attribution should be made visibly and unambiguously by displaying the exact Canalytics logo provided by Canalytics and the statement "Cannabis Effect Analysis Powered by Canalytics" adjacent to the Analysis Data. Failure to provide proper attribution will be considered a violation of these Terms of Service.  </span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>2.  Data Integrity</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">The Reposting Party must not alter, manipulate, truncate, or otherwise modify the Analysis Data in any way that could deceive or mislead consumers or the public regarding the scores and conclusions of the Analysis Data.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>3. Usage Limitations</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">The Reposting Party is authorized to use only the score data for each effect category of the Analysis Data for reposting purposes. Methodology, and other proprietary information contained in the Analysis Data are not to be shared, displayed, or republished without explicit written permission from Canalytics.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>4. Commercial Use</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">If the Client is engaged in commercial activities, any reposting or use of the Analysis Data for commercial purposes beyond the limitations set forth in these Terms must be explicitly authorized through a separate commercial agreement with Canalytics. Any unauthorized commercial use will be considered a violation of these Terms.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>5. License</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">By providing the Analysis Data, Canalytics grants the Reposting Party a limited, non-exclusive, non-transferable, and revocable license to use, display, and link to the Analysis Data solely for the purposes and under the conditions outlined in these Terms of Service.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>6. Quality Control</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1">
            <p dir="ltr" className="h-1"><span className="h-4">The Reposting Party must adhere to Canalytics' quality control guidelines for the display of the Analysis Data and any associated branding elements, including but not limited to logos and typography. These guidelines will be provided by Canalytics and may be updated from time to time. Failure to comply with these guidelines is considered a violation of these Terms.
            </span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>7. Compliance with Jurisdictional Laws</span></p>
        <ol className="h-5">
          <li dir="ltr" className="h-2" aria-level="1" >
            <p dir="ltr" className="h-1"><span className="h-4">All Clients, or any third-party entities authorized by the Client (hereinafter collectively referred to as the "Reposting Party"), who share, display, or repost Canalytics Analysis results, scores, or any other related content, are solely responsible for keeping up-to-date with and complying with the laws, regulations, and stipulations governing cannabis in their respective jurisdictions, whether it be states, counties, or countries.
            </span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1" style={{ marginTop: "10px" }}>
            <p dir="ltr" className="h-1"><span className="h-4">This responsibility extends to both recreational and medical cannabis markets and includes, but is not limited to, laws concerning what can be claimed, stated, promoted, or included on product packaging. Given the diverse and complex landscape of cannabis regulations that vary by jurisdiction, each Client or Reposting Party is responsible for meticulously evaluating what they are legally able to repost on a location-specific basis. It is the sole responsibility of the Reposting Party to determine which information, including but not limited to data, results, scores, and claims, can be legally reposted to the public, displayed, or used in compliance with the laws governing recreational or medical cannabis in their specific jurisdiction.</span></p>
          </li>
          <li dir="ltr" className="h-2" aria-level="1" style={{ marginTop: "10px" }}>
            <p dir="ltr" className="h-1"><span className="h-4">By sharing, displaying, or reposting Canalytics Analysis results or scores, the Reposting Party acknowledges and agrees that they are fully aware of, and will comply with, the laws and regulations that govern their actions with respect to cannabis in their jurisdiction.</span></p>
          </li>
        </ol>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>8. Third-party Sharing</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">The Reposting Party may not sub-license, distribute, transfer, or otherwise make available the Analysis Data to any third party, other than explicitly authorized third parties, without prior written consent from Canalytics. Any unauthorized sharing will be considered a violation of these Terms.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>9. Disclaimers</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">Whenever the Reposting Party shares or displays Analysis Data, a disclaimer must be included stating: "The analysis results were analyzed by Canalytics™ Effect Analysis tools.” Failure to include this disclaimer will be considered a violation of these Terms.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>10. Enforcement and Penalties</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">Canalytics reserves the right to take appropriate action, including but not limited to legal action and termination of service, against any Reposting Party found in violation of these Terms. Canalytics also reserves the right to seek damages and remedies available under the applicable laws.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>11. Indemnity Clause</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">The Reposting Party agrees to indemnify, defend, and hold harmless Canalytics, its directors, officers, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses, including but not limited to attorney's fees, arising from or related to the Reposting Party's misuse, alteration, or misrepresentation of the Analysis Data, or any other violation of these Terms.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>12. Audit Rights</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">Canalytics reserves the right, at its sole discretion, to audit the Reposting Party's websites, promotional materials, product labels, or any other medium where the Analysis Data is displayed or used, to ensure compliance with these Terms. The Reposting Party agrees to provide all necessary cooperation and access to facilitate such audits.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>13. Update Clause</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">These Terms and conditions related to the reposting and use of Analysis Data are subject to change at any time. It is the Reposting Party's responsibility to review Canalytics' Terms of Service regularly to stay informed of any updates. Continued use of the Analysis Data following updates to these Terms constitutes acceptance of the revised Terms.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3" style={{ paddingLeft: '15px' }}>14. Jurisdiction</span></p>
        <p dir="ltr" className="h-1" style={{ paddingLeft: '25px' }}><span className="h-4">Any disputes arising out of or related to the use, display, or reposting of Analysis Data shall be governed by the laws of the State of Delaware, without regard to its conflict of law principles. Both parties consent to the personal jurisdiction of the state and federal courts located in the State of Delaware for any actions arising out of or related to these Terms.</span></p>
        <p><br /></p>


        <p dir="ltr" className="h-1"><span className="h-3">3.5 Third-Party Content</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The App may contain links to third-party websites or resources. You acknowledge and agree that the Company does not have control over the availability, accuracy, or content of such third-party websites or resources. The inclusion of any such links does not imply endorsement by the Company. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any third-party content, products, or services available through the App. It is your responsibility to review and comply with the terms and conditions and privacy policies of any third-party websites or resources that you access through the App.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">4) Analysis Services</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.1 Scope of Services</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Canalytics web app provides analysis services for lab-tested Certificate of Analysis (COA) reports of cannabis products. The analysis services focus on evaluating the therapeutic and adult use effects of cannabis products based on their cannabinoid and terpene profiles. By utilizing the App, users can access detailed information and insights regarding the composition and potential effects of cannabis products as indicated by the provided COA reports. However, it is important to note that the analysis results are dependent on the accuracy and completeness of the data provided in the COA reports, as well as the capabilities of the Company&apos;s database and algorithms.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.2 Performance Standards</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics aims to provide high-quality deliverables to its clients. While the Company strives for excellence, it is important to establish the following performance standards to ensure transparency and set realistic expectations:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-3">a. Accuracy:&nbsp;</span><span className="h-4">Canalytics, hereinafter referred to as &quot;the Company,&quot; is committed to providing deliverables with a high level of accuracy. However, it is important to acknowledge that data analysis and interpretation inherently have limitations, and therefore, the Company cannot guarantee absolute accuracy. The accuracy of the deliverables is influenced by various factors, including the quality and accuracy of the provided COA data, the selection of terpenes analyzed by the respective laboratories, the quality of the cannabis material being analyzed, overall data quality, and potential limitations or biases in some primary studies. The Company will make diligent efforts to ensure the accuracy of the deliverables within these acknowledged limitations. The Company will exert reasonable efforts to ensure the accuracy of the deliverables within these limitations.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-3">b. Timeliness:&nbsp;</span><span className="h-4">The Company recognizes the significance of timely delivery in providing its services. We are committed to completing the deliverables within the agreed-upon timeframe. However, it is important to acknowledge that unforeseen circumstances, project complexity, or external dependencies may occasionally impact the estimated delivery schedule. For instance, if we receive a COA from a laboratory that we haven&apos;t previously analyzed, we may require additional time to process a new COA format for the first time. In such cases, the Company will communicate any necessary adjustments to the client to ensure transparency and manage expectations effectively.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-3">c. Compliance:&nbsp;</span><span className="h-4">The Company will adhere to all applicable laws, regulations, and industry standards while performing its services.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-3">d. Confidentiality:</span><span className="h-4">&nbsp;The Company acknowledges the sensitive nature of client data and is committed to handling it with care and confidentiality. The Company will implement robust security measures to ensure the protection of client data. These measures are designed to maintain the privacy and integrity of the client&apos;s data throughout the engagement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.2.1 Additional Information</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that the above performance standards and methodology are provided for illustrative purposes and may be subject to customization based on the specific engagement with Canalytics. It is recommended that clients review and discuss these terms within the context of their contractual agreement with the Company.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.3 Methodology</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics follows a systematic methodology to ensure the delivery of orders. The following outlines the typical steps involved in the Company&apos;s methodology:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">a. Data Collection&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company collects cannabis lab-tested data from clients in the form of Certificate of Analysis (COAs) provided as PDF files or compatible digital formats. This data is essential for conducting analysis, evaluations, and generating static and interactive GUI displays of results and actionable information. It is important to note that for analysis on the Canalytics platform, client COAs must include terpene analysis data.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In cases where clients upload COAs that solely contain cannabinoid or toxin information without terpene data, their order will be temporarily paused until COAs with terpene data are provided. This pause ensures that the analysis process can incorporate the necessary terpene data to maintain the robustness of data required by Canalytics&rsquo; algorithms.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">b. Data Analysis</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company will utilize its advanced analytical techniques to analyze the collected data. This may involve employing various methodologies such as statistical data analysis, COA data mining, predictive modeling, or any other suitable approaches based on the specific requirements of the project. The analysis process is conducted digitally utilizing Canalytics&rsquo; software analysis platform.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">c. Interpretation and Insights</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Once the data analysis is complete, the Company will interpret the findings and generate completed order pages with interactive visualization tools to view the analysis results. These insights will be presented to the client via a web app that allows clients to view the analysis results as actionable interactive visualizations and reports for all of their orders in a single dashboard.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">d. Reporting and Delivery</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company will prepare completed order analysis results including interactive visualizations or any other deliverables agreed upon with the client. The delivery format will be via our web app dashboard and may also include additional digital files.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">4.4 Delivery of Services</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.4.1 Analysis Order Placement</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Once clients have created an account, they can initiate a new analysis order through the website&apos;s interface. The order placement process will involve specifying the desired analysis requirements, such as the tier of analysis depth and the amount of COAs to be analyzed. Clients will have the flexibility to choose from a range of analysis options tailored to their needs.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.4.2 COA PDF File Upload</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">As part of the analysis order, clients will be required to upload one or multiple Certificate of Analysis (COA) as PDF files through the web application. These COA files contain the relevant lab test results of the cannabis samples being analyzed by Canalytics. Uploading these files is a critical step as it provides the necessary data for the analysis process. Canalytics can only analyze COA submissions that include terpene data of the sample. The Company will ensure the security and confidentiality of the uploaded files.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.4.3 Analysis Completion Timeframe</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Upon successful submission of the analysis order and COA files, the Company will strive to complete the analysis within a general timeframe of 24 to 72 hours. This timeframe may vary depending on the complexity of the analysis, the volume of orders, and any unforeseen circumstances. In cases where a COA is submitted from an analysis lab we haven&rsquo;t analyzed previously, it may take an additional 1-3 days to train our system to process new COA PDF formats. The Company will make every effort to deliver the results promptly while maintaining accuracy and quality.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.4.4 Dashboard Access and Result Viewing</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Once the analysis is complete, clients will receive a notification of the availability of their results. The user can log in to their account dashboard on the Canalytics WebApp to access the results. The dashboard will provide a user-friendly interface where clients can view and navigate through the results of all their orders.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.4.5 Phenotype Matchmaker Tool</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In addition to analysis services, the Company will soon offer access to Canalytics Phenotype Matchmaker tools. Once these features are launched, clients will be able to utilize additional tools to identify the closest matches to specific cannabinoid and terpene ratio profiles within their collection of COAs. To use this tool, clients will sign in to their account dashboard and open the phenotype matchmaker tool within the web application.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-3">a. Phenotype Matchmaker Desired Profile Input and Analysis</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-10"><span className="h-4">When it is released, within the Phenotype Matchmaker tool, clients can input their desired values for cannabinoids and terpenes profiles, enabling them to identify the closest matches that meet their preferences. The web app will then analyze all the COA lab results associated with the client&apos;s account and generate a detailed report on the match variance for each COA. This report will highlight which COAs are the closest match to the desired profile, allowing clients to make quick informed decisions across thousands of phenotypes based on pre-defined preferences.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">4.5 Data Privacy</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company highly values the confidentiality and privacy of your data. We are committed to protecting your personal information and handling it in accordance with applicable data protection laws and regulations. Our Privacy Policy outlines the specific details regarding how we collect, use, process, store, and protect your personal information. By using the App, you consent to the collection, use, and processing of your personal information as described in our Privacy Policy.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">It is important to note that while the Company takes reasonable measures to safeguard your data, no method of data transmission or storage can be guaranteed to be completely secure. Therefore, the Company cannot ensure the absolute security of your data and shall not be held liable for any unauthorized access, disclosure, or loss of your data that is beyond our reasonable control.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">5) Fees and Payment</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">5.1 Pricing Model</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Canalytics web app operates on a pricing model that is based on the number of Certificate of Analysis (COA) reports analyzed and the depth of analysis required. The specific pricing details, including the applicable fees and the factors considered for determining the analysis charges, will be provided to you during the ordering process. Please note that the pricing model may be subject to change at the Company&apos;s discretion. Any changes to the pricing model will be communicated to you in advance.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">5.2 Payment Terms</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By using the App and accessing the analysis services, you agree to pay all fees associated with the analysis services as outlined in the pricing model provided to you. The fees must be paid in full before you complete submission of an order or access the analysis results and reports generated by the App for a specific order.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Prior to completing an analysis order submission, the web application will require that end users complete an online payment or utilize pre-purchased analysis credits prior to completing the submission of an order. The online payment invoice will include a breakdown of the charges based on the selected analysis depth and the number of COA files uploaded. The invoice will be made available to clients, where they can review the details and complete the payment.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">To facilitate the payment process, the Company may utilize third-party payment processors. When making payments, you agree to comply with the terms and conditions of the specific payment processor used. The Company does not store or have access to your payment card or financial information. All payment transactions are subject to the terms and conditions of the payment processor and their privacy and security policies.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">5.3 Refunds</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Refunds for analysis services may be available under certain circumstances, as outlined in our Refund Policy. The Refund Policy provides detailed information regarding eligibility for refunds and the process for requesting a refund. It is important that you review and understand the Refund Policy before initiating any refund requests. The Company reserves the right to review and evaluate refund requests on a case-by-case basis, and the determination of eligibility for a refund will be at the Company&apos;s sole discretion.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that any refunds granted will be subject to the terms and conditions specified in the Refund Policy. Refunds, if approved, will be processed using the same payment method used for the original transaction, unless otherwise agreed upon by the Company and the User.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">5.3 Additional Information</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that the fees, payment terms, and processes described above are provided as a general outline and may be subject to specific terms and conditions agreed upon between the client and Canalytics. Clients are encouraged to review and discuss the detailed fees and payment terms within the context of their contractual agreement with the Company.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">6) Limitation of Liability and Disclaimer of Warranties</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By engaging in any services or purchasing any products from &quot;Canalytics,&quot; the client agrees to the following terms and conditions regarding the delivery, use and interpretation of analyses, reports, evaluations, and any deliverables provided by Canalytics:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.1 Database and Algorithms</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client acknowledges that Canalytics leverages a scholarly database consisting of thousands of relevant primary studies regarding therapeutic and adult use effects of cannabinoids and terpenes, alongside its proprietary algorithms, to perform analyses on the effects of certificate of analysis (COA) lab results. The client understands and agrees that all deliverables provided will be generated by the Canalytics platform based on the algorithms and the current state and limitations of the database of studies available at the time of the analysis.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Performance Standards: Canalytics aims to provide high-quality deliverables to its clients. While the Company strives for excellence, it is important to establish the following performance standards to ensure transparency and set realistic expectations:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Accuracy: Canalytics, hereinafter referred to as &quot;the Company,&quot; is committed to providing deliverables with a high level of accuracy. However, it is important to acknowledge that data analysis and interpretation inherently have limitations, and therefore, the Company cannot guarantee absolute accuracy. The accuracy of the deliverables is influenced by various factors, including the quality and accuracy of the provided COA data, the selection of terpenes analyzed by the respective laboratories, the quality of the cannabis material being analyzed, overall data quality, and potential limitations or biases in some primary studies. The Company will exert reasonable efforts to ensure the accuracy of the deliverables within these acknowledged limitations.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">It is important to note that the analysis results are based on the data provided in the COA reports and the current state of the Company&apos;s database and algorithms. The App is not intended to diagnose, treat, cure, or prevent any disease or therapeutic condition, and any information provided through the App should not be considered as therapeutic advice. Users are advised to consult with a qualified healthcare professional before making any decisions or taking any actions based on the analysis results obtained through the App.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.2 Limitation of Liability</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Once Canalytics delivers the final analyses, reports, evaluations, or any other relevant deliverables to the client, it completes the sale transaction. The client agrees that Canalytics cannot be held liable for any actions, decisions, or consequences arising from the client&apos;s use, interpretation, or reliance upon the analysis, data, or any information contained in the deliverables.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.3 Client&apos;s Responsibility</span><span className="h-4"><br /><br /></span></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client acknowledges and understands that the analyses, reports, evaluations, and any other deliverables provided by Canalytics are intended to provide insights and information based on the proprietary algorithms and the current database of studies. While Canalytics strives for accuracy and reliability, the client acknowledges that the deliverables are not intended to substitute professional advice and therapeutic guidance in instances where it is needed. The client assumes full responsibility for evaluating and interpreting the deliverables in conjunction with their own knowledge, expertise, and judgment. The client should take care of seeking appropriate professional advice or consultation when needed before making any decisions or taking any actions based on the deliverables provided by Canalytics. Canalytics shall not be held liable for any loss, damage, or injury arising from the client&apos;s use, misuse, or reliance upon the deliverables.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.4 Dispensaries and Retail Outlets</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">When providing analysis services to dispensaries and retail cannabis outlets, Canalytics recognizes that each state has its own unique rules, laws, and stipulations regarding the permissible actions and recommendations that dispensaries can undertake with customers and the public. The client, specifically dispensaries, retail outlets, and any other related entities, agrees to the following terms and responsibilities:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.4.1 Legal Compliance</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Dispensaries and retail outlets acknowledge that it is their sole responsibility to research, understand, and comply with the laws, regulations, and terms governing their operations in their respective jurisdictions. This includes but is not limited to the legality of recommending adult use or therapeutic cannabis profiles or products to customers. Canalytics does not provide legal advice or guidance in this regard and cannot be held liable for any improper use of the insights and information provided by Canalytics.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.4.2 Independent Research</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Dispensaries and retail outlets understand that they are obligated to independently verify the legal requirements and limitations regarding the information and recommendations they can provide to customers. This may involve consulting legal professionals or relevant regulatory authorities to ensure compliance with applicable laws, regulations, and industry standards.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.4.3 Limitation of Liability</span></p>
        <p dir="ltr" className="h-1"><span className="h-4"><br /></span><span className="h-4">Canalytics expressly disclaims any liability for the actions, decisions, or consequences resulting from the dispensary or retail outlet&apos;s use, misuse, or reliance upon the insights, information, analyses, or any other deliverables provided. Canalytics cannot be held responsible for any legal violations, damages, losses, or liabilities incurred by the dispensary or retail outlet as a result of their use or dissemination of the Canalytics deliverables and data.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.4.4 Professional Responsibility</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Dispensaries and retail outlets acknowledge their professional duty to provide accurate, lawful, and responsible information and recommendations to their customers. They assume full responsibility for the appropriate and compliant use of the insights and information derived from Canalytics&apos; deliverables.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.5 Suggestions to modify prescription medicine or doctor recommendations</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics expressly prohibits the use of its findings to advise, suggest or endorse modifications of a person&apos;s non-cannabis prescription medication usage, medicine dosage adjustments, or discontinuation of any therapeutic prescriptions or change of any recommendations by a therapeutic professional. It is mandatory for patients, dispensaries, and individuals involved in customer interactions, especially those seeking therapeutic benefits, to consistently advocate that patients consult their doctor or therapeutic professionals for all medical-related decisions, treatment options and basis for modifying any of those recommendations.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.6 Continuing Compliance Assessment&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Dispensaries and retail outlets understand that laws, regulations, and industry standards are subject to change. They agree to regularly review and reassess the legal requirements and restrictions governing their operations, ensuring ongoing compliance with the most up-to-date guidelines in their jurisdiction.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In summary, when engaging with Canalytics and utilizing the deliverables and data provided, dispensaries, retail outlets, and all other Canalytics clients assume full responsibility for researching, understanding, and adhering to the laws, regulations, and terms governing their operations.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics does not provide legal advice, and any improper use or non-compliance by the dispensary or retail outlet is their sole responsibility. It is strongly recommended that dispensaries and retail outlets consult legal professionals and relevant regulatory authorities to ensure compliance with all applicable laws, regulations, and industry standards.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.7 Accuracy of Analysis</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company strives to provide accurate and reliable analysis results through the App. However, you acknowledge and understand that the analysis is dependent on the data provided in the COA reports and the current state of the Company&apos;s database and algorithms. Therefore, the analysis results may not be infallible or completely precise. The Company makes no guarantees or warranties regarding the absolute accuracy or absolute reliability of the analysis results.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You agree that any actions, decisions, or reliance on the analysis results obtained through the App are solely at your own risk. The Company shall not be held liable for any consequences, damages, or losses arising from your use of the analysis results or any actions taken based on such results.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">6.8 Additional Information</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By engaging in any services or purchasing any products from Canalytics, the client acknowledges that they have read, understood, and agreed to all the terms and conditions in this agreement including the Indemnification Clause.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">7) Intellectual Property Rights:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.1 Ownership of Deliverables</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics, hereinafter referred to as &quot;the Company,&quot; understands the importance of providing clients with the ability to utilize the analysis results and reports for their business purposes. As such, the ownership and usage terms for the deliverables are as follows:</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.1.1 Usage Restrictions</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client is granted a non-exclusive, non-transferable license to use the analysis results and reports solely for their internal business purposes. This license prohibits the client from reselling, sublicensing, or relabeling the Canalytics deliverables and reports as their own. The client may not represent the analysis results and reports as the work of their own company or claim intellectual property rights over the deliverables.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.1.2 Acknowledgment of Company&apos;s Ownership</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client acknowledges that the analysis results and reports are the product of the Company&apos;s expertise, methodologies, and proprietary algorithms. The client agrees to properly attribute the Company&apos;s contribution when referencing or citing the deliverables in any external communications, presentations, or publications.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.1.3 Protection of Third-Party Intellectual Property</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client further agrees not to infringe upon the intellectual property rights of third parties while using the deliverables. The client will respect any copyrights, trademarks, or other intellectual property rights associated with third-party content or data included in the deliverables.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.2 Confidentiality and Data Privacy</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">While the reports and analysis results may not contain proprietary or confidential information, the Company still acknowledges the importance of maintaining the confidentiality of client data. The Company will handle client data in accordance with applicable privacy laws and regulations, ensuring that the client&apos;s data is protected.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.3 Client Data and Privacy</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client retains ownership of any data or information provided to the Company for the purpose of analysis or matchmaking services. The client grants the Company a limited license to use the client data for the purpose of performing the analysis services. The Company will not use the client data for any other purpose or disclose it to third parties without the client&apos;s consent, unless required by law.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.4 Feedback and Improvements</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The client may provide feedback, suggestions, or recommendations to the Company regarding the analysis services or deliverables. The Company appreciates such input; however, the client acknowledges and agrees that any feedback or suggestions provided will not create any obligation on the Company to incorporate them into its services or modify the deliverables. The Company will have full discretion to decide whether or not to implement any changes or improvements based on client feedback.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.5 Third-Party Intellectual Property</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company respects the intellectual property rights of third parties. In performing the analysis services, the Company will not knowingly infringe upon any third-party intellectual property rights. The client agrees to indemnify and hold the Company harmless from any claims, damages, or liabilities arising out of any alleged infringement of third-party intellectual property rights resulting from the client&apos;s use of the deliverables or provision of data.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">7.6 Termination and Effect of Termination</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In the event of termination or expiration of the client&apos;s agreement with the Company, the client can continue internal use of the orders and deliverables that they previously purchased.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">8) Confidentiality and Data Security</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">8.1 Confidentiality Obligations</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company acknowledges and understands the importance of maintaining the confidentiality of the data and information you provide through the Canalytics web app. We will treat all Certificate of Analysis (COA) reports and any other data you provide as strictly confidential. The Company will not disclose this information to any third party without your prior written consent, except as required by law or as necessary to provide the analysis services.</span></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that the Company may engage the services of trusted third-party service providers, including but not limited to data hosting and storage providers, to assist in the operation and maintenance of the App. In such cases, these third-party service providers will be contractually obligated to maintain the confidentiality and security of your data and will only process your data in accordance with our instructions.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">8.2 Data Security</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company takes reasonable measures to implement and maintain appropriate technical and organizational security measures to protect your data from unauthorized access, use, or disclosure. However, it is important to acknowledge that no method of transmission or storage over the internet is completely secure. Therefore, while the Company strives to protect your data, we cannot guarantee the absolute security of your data against all potential threats and risks.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You acknowledge and accept that there are inherent risks associated with the transmission and storage of data, and you understand that the security of your data is ultimately dependent on factors beyond the Company&apos;s control. You agree that the Company shall not be held liable for any unauthorized access, use, or disclosure of your data that is beyond our reasonable control.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">8.3 Data Retention</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company will retain your data for as long as necessary to provide the analysis services to you and to comply with our legal obligations. The retention period may vary depending on the nature of the data and the purposes for which it is processed. After the retention period, we will securely delete or anonymize your data in accordance with applicable laws and regulations.</span></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that even after the deletion or anonymization of your data, residual copies may remain in the Company&apos;s backup systems or archives. The Company will take reasonable measures to ensure that any retained data continues to be protected in accordance with this Agreement and our data protection practices.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">It is important to review our Privacy Policy for more detailed information on how we handle and protect your data, including our data retention practices and procedures.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">9) Term and Termination</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.1 Termination by You</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">You have the right to terminate your use of the Canalytics web app at any time by closing your account and ceasing to access the App. To close your account, you may follow the account closure procedure provided within the App. Upon termination, you will no longer have access to the analysis results and reports provided by the App.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.2 Termination by Us</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company reserves the right to suspend or terminate your access to the Canalytics web app at any time and for any reason, without prior notice or liability. This includes situations where we believe, in our sole discretion, that your use of the App violates these Terms of Service or any applicable laws or regulations, or if we determine that such action is necessary to protect the security or integrity of the App or the rights and interests of the Company or other users. Users who are terminated will receive access to their previously purchased analysis results, but are no longer able to place new orders.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.2.1</span><span className="h-4">&nbsp;</span><span className="h-3">Access to Previously Purchased Reports</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Upon termination, the client will continue to have access to the analysis reports that were purchased and delivered during the term of the engagement.&nbsp;</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.2.2</span><span className="h-4">&nbsp;</span><span className="h-3">Termination for Convenience</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Either party may terminate the engagement for any reason by providing written notice to the other party within the agreed termination notice period. The termination notice period shall be 72 hours of prior notice.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.2.3 Termination for Cause</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Either party may terminate the engagement for cause in the event of a material breach of the agreement by the other party. The terminating party shall provide written notice specifying the nature of the breach, and the breaching party shall have a reasonable opportunity to cure the breach, if curable, within a specified period of time. If the breach remains uncured, the terminating party may proceed with the termination.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.3 Effect of Termination</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Upon termination, you understand and acknowledge that you will no longer have access to the analysis results and reports provided by the App. Any outstanding fees or obligations owed to the Company shall remain due and payable.</span><span className="h-4"><br /></span><span className="h-4"><br /></span><span className="h-4">Termination of your account and use of the App does not relieve you from any liabilities or obligations incurred prior to termination, including but not limited to any outstanding fees or obligations. The provisions of this Agreement that, by their nature, should survive termination, including but not limited to the provisions related to intellectual property rights, confidentiality, and limitation of liability, shall continue to apply even after termination.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">9.4 Survival of Provisions</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Any provisions of the agreement that, by their nature, should survive termination will continue to apply following the termination. This may include, but is not limited to, provisions related to confidentiality, intellectual property rights, limitation of liability, and dispute resolution.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">10) Governing Law and Dispute Resolution</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">10.1 Governing Law</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of laws principles. Any disputes, claims, or legal actions arising from or in connection with this Agreement shall be subject to the jurisdiction of the courts located in the State of Delaware.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">10.2 Dispute Resolution</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company and the User agree to make reasonable efforts to resolve any dispute, controversy, or claim arising out of or relating to this Agreement through good faith negotiations. Both parties shall endeavor to reach a mutually satisfactory resolution within a reasonable timeframe.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In the event that the dispute cannot be resolved through negotiations, either party may initiate mediation by providing written notice to the other party. The mediation shall be conducted in accordance with the rules and procedures of a mutually agreed-upon mediation provider. The mediator&apos;s role will be to facilitate communication and assist the parties in reaching a voluntary resolution.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">If mediation is unsuccessful or if both parties agree in writing to bypass mediation, any unresolved dispute, controversy, or claim shall be submitted to binding arbitration. The arbitration shall be conducted in accordance with the rules and procedures of Arbitration in the State of Delaware. The arbitration shall take place in the State of Delaware, unless otherwise agreed by the parties. The decision and award rendered by the arbitrator(s) shall be final and binding on both parties.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The arbitration proceedings shall be conducted in the English language. Each party shall bear its own costs and expenses, including legal fees, associated with the arbitration, unless the arbitrator(s) determine otherwise. The prevailing party in the arbitration may be entitled to recover its reasonable attorneys&apos; fees and costs, to the extent permitted by applicable law.</span><span className="h-4"><br /><br /></span></p>
        <p dir="ltr" className="h-1"><span className="h-4">Notwithstanding the above, either party may seek equitable relief from a court of competent jurisdiction for a breach or threatened breach of confidentiality obligations or intellectual property rights.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By using the Canalytics web app, you agree to waive any right to participate in a class or collective action or to assert any claims as a representative or member of a class or collective action, unless such waiver is prohibited by applicable law.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">This provision does not limit the right of either party to seek injunctive or other equitable relief from a court of competent jurisdiction to prevent or restrain the infringement or misappropriation of its intellectual property rights or to enforce the confidentiality obligations under this Agreement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">10.3 Additional Information</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Please note that the &quot;Governing Law and Dispute Resolution&quot; section provided above is intended as a general guideline and may be subject to specific terms and conditions agreed upon between the client and Canalytics. Clients are advised to review and discuss the detailed governing law and dispute resolution provisions within the context of their contractual agreement with the Company.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">11) Refund Policy</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Canalytics Inc. (&ldquo;Canalytics&rdquo;) thanks you for using our cannabis effect analysis services provided via our web application. Your satisfaction is important to us, however, due to the nature of our services, we have a strict refund policy in place. Please read the following refund policy (&quot;Refund Policy&quot;) carefully.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">11.1 Completed Orders</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Once an order is completed by Canalytics and the analysis results, insights, and visualizations have been delivered to you (&quot;Client&quot;), no refunds will be issued. This is due to the fact that the service provided is informational and once the data is shared with you, it cannot be returned or revoked. Since you, as a Client, will have received and presumably saved copies of the valuable insights, results, and analysis data associated with your order, Canalytics will have fulfilled its obligation and completed the order.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">11.2 Incomplete Orders</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">However, in the event that Canalytics is unable to complete an order for any reason, a full refund will be provided to you. Reasons for inability to complete an order may include, but are not limited to, orders where Clients do not submit complete lab results. Please note that our analysis requires both cannabinoid and terpene data. If any of these necessary data points are missing from the submitted lab results, Canalytics will not be able to complete the order.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">11.3 Requesting Refunds</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">In case your order falls under the category of Incomplete Orders as specified above, you may request a refund by contacting our Customer Support Team. Once we have confirmed that your order is indeed incomplete due to the reasons listed above, we will initiate a refund.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The refund will be made to the original payment method, unless expressly agreed otherwise, and will be processed as quickly as possible. Refunds may take several business days to reflect on your credit card statement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By using Canalytics&apos; services, you acknowledge that you have read, understood, and agree to be bound by this Refund Policy. Canalytics reserves the right to modify this Refund Policy at any time. Changes will be effective immediately upon posting on the website. Please check this page regularly to ensure you are familiar with the current version.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">For any queries regarding this policy, please reach out to our Customer Support Team.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Last updated: 6/30/2023</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-9">12) Miscellaneous</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.1 Entire Agreement</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">This Agreement constitutes the entire agreement between you and the Company regarding the use of the Canalytics web app and supersedes any prior agreements, understandings, or representations, whether written or oral, relating to the subject matter of this Agreement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.2 Severability</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect. The parties shall make reasonable efforts to replace the invalid or unenforceable provision with a valid and enforceable provision that achieves the original intent of the Agreement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.3 Waiver</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The failure to enforce any provision of this Agreement shall not be deemed a waiver of that provision or the right to enforce it. Any waiver of any provision of this Agreement must be in writing and signed by the party granting the waiver.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.4 Assignment</span><span className="h-4"><br /></span><span className="h-4"><br /></span><span className="h-4">You may not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company. The Company reserves the right to assign or transfer this Agreement, in whole or in part, to a third party without your consent.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.5 Relationship of the Parties</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Nothing in this Agreement shall be construed as creating a partnership, joint venture, agency, or employment relationship between you and the Company. You acknowledge and agree that you are an independent contractor and that this Agreement does not create any obligation for the Company to provide employment benefits, including but not limited to healthcare benefits, retirement benefits, or paid time off.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.6 Notices</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">Any notices or communications required or permitted under this Agreement shall be in writing and delivered by email or by certified mail to the addresses provided by the parties. Notices sent by email shall be deemed received on the date of transmission, and notices sent by certified mail shall be deemed received three (3) business days after the date of mailing.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-3">12.7 Amendments</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">The Company reserves the right to modify or amend this Agreement at any time in its sole discretion. Any changes to this Agreement will be effective upon posting the revised Agreement on the Canalytics web app. Your continued use of the App after the posting of any changes constitutes your acceptance of the modified Agreement.</span></p>
        <p><br /></p>
        <p dir="ltr" className="h-1"><span className="h-4">By accessing or using the Canalytics web app, you acknowledge that you have read, understood, and agree to be bound by this Terms of Service Agreement. If you do not agree to these terms, you may not use the App.</span></p>
        <p><br /></p>
        <p><br /></p>
      </div>
      {/* <Link
          to='/login'
          className="my-button"
          style={{ backgroundColor: "#609BE5", color: "#fff", textAlign: 'center', marginTop: '15px' }}
        >
          Back
        </Link> */}
    </div>

  );

}

export default TermCondition;
