// Libraries
import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Select, Upload, Alert, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import Layout from "../../../components/layout/Layout";
import MyButton from "../../../components/mybutton/MyButton";
import DonutChart from "../../../components/donutchart/DonutChart";
import ColumnChart from "../../../components/columnchart/ColumnChart";
import ChartTable from "../../../components/charttable/ChartTable";
import PdfFileLists from "../../../components/pdffilelists/PdfFileLists";

// Styles
import "./index.css";
import StackedChart from "../../../components/stackedchart/StackedChart";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../shared/interceptor";
import { CustomerType, packageValue, packageValueNew } from "../../../data/admin/DDL";
import { UploadFile } from "../../../icons/Icons";
import { error } from "../../../components/Notifications";




const { Title, Text } = Typography;
function CreateOrder() {
  const userList = useSelector((state) => state.userReducer.userList)
  const { id } = useParams()
  const [orders, setOrders] = useState(null)
  const [visual, setVisual] = useState(null)
  const [subVisual, setSubVisual] = useState(null)
  const [user, setUser] = useState(null)
  const [packages, setPackage] = useState(null)
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()
  const [fileList, setFileList] = useState()
  const [fileUrl, setFileUrl] = useState();
  const [message, SetMessage] = useState(null);

  const { token, isAdmin } = useToken()
  const naviagte = useNavigate();


  const handleChange = (info) => {
    SetMessage(null)
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // uploadImage(info.file,)
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setLoading(false);
      //   setImageUrl(url);
      // });
    }
  };
  const beforeUpload = (file) => {
    SetMessage(null)
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng && !isAdmin) {
      message.error("You can only upload PDF files!");
      return false
    }
    return true
  };

  const uploadImage = () => {
    if (user && fileList && packages && name) {

      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.Accept = 'application/json';
      var formdata = new FormData();
      formdata.append("admin_file", fileList)
      formdata.append("user_id", user)
      formdata.append("package_selected", packages)
      formdata.append("name", name.target.value)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',

      };
      let url = 'create_order_on_behalf_of_user/'
      fetch(process.env.REACT_APP_BASE_URL + url, requestOptions)
        .then(res => res.json())
        .then(res => {
          debugger
          if (res && res.message !== "File format is not accurate") {
            naviagte("/deliver-order/" + res.data.id);
          } else {
            SetMessage(res.message)
            error("topRight", res.message)
          }
          setLoading(false)

        }).catch(err => {
          setLoading(false)

        })
    }
    else {
      SetMessage("Please Selec User, Package and Upload File")
    }
  }

  const uploadButton = (
    <div style={{
      padding: "30px 0",
    }}>
      {loading ? <LoadingOutlined /> : <UploadFile color="#939393" />}
      <div
        className="upload-box"
      >
        <Text>  Drag & Drop Files Here  </Text>
        <Text>Or</Text>
        <Button loading={loading} >  Browse Files</Button>
      </div>
      {message && <span style={{ color: 'red' }}>{message} </span>}
    </div>
  );

  const approveDoucment = () => {
    setLoading(true)
    let model = {
      status: "COMPLETED",
      id: id
    }
    interceptor.axiosPost('Post', 'admin/change-status-of-order/', model, token).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        naviagte("/main-page");

      }
      else {
        console.log("error getting user : ", res)
      }
    }).catch((err) => {
      return err
    })
  }

  useEffect(() => {
    if (userList?.length === 0) {
      dispatch(userActions.getUser(token))
    }
  })

  const getDetail = (value) => {
    debugger
    userActions.getUserOrders(token, value.id).then(async res => {
      await setOrders(res)
      userActions.getVisualOrderDetail(token, value.id).then(async res => {
        await setVisual(res)
        userActions.getSubVisualDetail(token, value.id, 1).then(async res => {
          await setSubVisual(res)
        })
      })
    })
  }

  const Onchange = (value) => {
    console.log(value)
    setLoading(true)
    userActions.getSubVisualDetail(token, id, value).then(async res => {
      await setSubVisual(res)
      setLoading(false)

    })
  }

  const handleChangeImage = (file) => {
    console.log('fileList', file.file);
    setFileList(file.file.originFileObj);
  };

  const changeUser = (value) => {
    console.log(value)
  }

  return (
    <Layout title="Deliver Order: Download PDFs & Deliver CSV">
      <div className="deliver-order">
        {isAdmin &&
          <>
            < div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '5px' }} >
                <Title level={3}>Order Title</Title>
                <Input style={{ width: '300px', marginBottom: '15px', }} onKeyUp={setName} />
              </div>
              <div >
                <Title level={3}>Select User</Title>
                <Select
                  placeholder="Type"
                  showArrow
                  allowClear
                  onChange={setUser}
                  style={{ width: '300px', marginBottom: '15px', paddingRight: '5px' }}
                >
                  {userList?.users?.map((item) => (
                    <>
                      {
                        item.name !== 'Others' && item.name !== 'All' &&
                        <Select.Option key={item.id} value={item.id}

                          change={() => changeUser(item.id)}>
                          {item.email}
                        </Select.Option>
                      }
                    </>
                  ))}
                </Select>
              </div>
              <div>
                <Title level={3}>Select Package</Title>
                <Select
                  placeholder="Package"
                  showArrow
                  allowClear
                  onChange={setPackage}
                  style={{ width: '300px', marginBottom: '15px' }}
                >
                  {packageValueNew.map((item) => (
                    <>
                      {
                        <Select.Option key={item.key} value={item.id}>
                          {item.name}
                        </Select.Option>
                      }
                    </>
                  ))}
                </Select>
              </div>


            </div>
            <Row gutter={16} align="top">
              <Col className="gutter-row" xl={12} lg={24} md={24} sm={24}>
                {orders?.status != 'COMPLETED' &&
                  <div className="upload-card">
                    <Title level={4}>Admin: Upload CSV to Preview & Deliver Order</Title>
                    <div className="upload-file">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        accept={'.csv,.xlsx'}
                        onChange={handleChangeImage}
                      >
                        {fileList ? (
                          <span> {fileList.name}</span>
                        ) :
                          uploadButton
                        }

                      </Upload>
                    </div>
                  </div>}
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  {orders?.status != 'COMPLETED' &&
                    <>
                      <Button
                        className="my-button"
                        style={{
                          backgroundColor: "#609BE5",
                          color: "#fff",
                          width: "initial",
                          display: "inline-block",
                          marginRight: '5px'
                        }}
                        htmlType="submit"
                        onClick={uploadImage}
                        loading={loading}
                      >
                        Preview
                      </Button>
                      {message ? <Alert type='error' message={message} /> : ''}
                      {/* <Button
                        className="my-button"
                        style={{
                          backgroundColor: "#609BE5",
                          color: "#fff",
                          width: "initial",
                          display: "inline-block",
                        }}
                        htmlType="submit"
                        onClick={approveDoucment}
                        loading={loading}
                      >
                        Approve Preview & Deliver
                      </Button> */}
                    </>
                  }
                </div>
              </Col>
            </Row>
          </>
        }

        {orders?.is_admin_uploaded_data &&
          <>
            <div className="area-chart">
              <StackedChart showTitle={true} item={visual} change={Onchange} title={orders?.name} />
            </div>
            <div className="donut-chart-meta stacked-chart stacked-chart-selection">
              <Title style={{ marginBottom: '0px' }} level={5}>Selection: {subVisual?.strain_name}</Title>
              <ColumnChart item={subVisual} loading={loading} />
              <Row gutter={16} align="top" style={{ marginTop: 30 }}>
                <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
                  <DonutChart id={'chart1'} item={subVisual?.donout_graph} loading={loading} title={'Terpene %'} subTitle={'Terpene Volume'} />
                </Col>
                <Col className="gutter-row donut" xl={12} lg={24} md={24} sm={24}>
                  <DonutChart id={'chart2'} item={subVisual?.donout_graph_2} loading={loading} title={'Cannabinoid %'} subTitle={'Cannabinoid Volume'} />
                </Col>
              </Row>
            </div>
            <ChartTable item={visual} />
            <div style={{ textAlign: "center" }}>
              {orders?.status != 'COMPLETED' &&
                <>
                  <Button
                    className="my-button"
                    style={{
                      backgroundColor: "#609BE5",
                      color: "#fff",
                      width: "initial",
                      display: "inline-block",
                    }}
                    htmlType="submit"
                    onClick={approveDoucment}
                    loading={loading}
                  >
                    Approve Preview & Deliver
                  </Button>

                </>
              }
            </div>
          </>
        }
      </div>
    </Layout>
  );
}

export default CreateOrder;
