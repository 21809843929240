// Libraries
import React from "react";
import { Typography, List, Input, Form } from "antd";

const { Title, Text } = Typography;
function CustomPricing(props) {
  return (
    <>
      <div className="custom-pricing-header">
        <Title level={2}>{props.title}</Title>
        {props.global && <div className="discount-price">
          <Text>Global Discount:</Text>
          <Form.Item
            name="global_discount"
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="0%" />
          </Form.Item>
        </div>}
      </div>
      <List className="pricing-label" style={{marginTop: !props.global? '73px' : '0px'}}>
        {props?.data.map((item, index) => (
          <List.Item key={index}>
            <Typography.Text>{item.item}</Typography.Text>
          </List.Item>
        ))}
      </List>
    </>
  );
}

export default CustomPricing;
