// Libraries
import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

// Components
import Layout from "../../../components/layout/Layout";
import MyButton from "../../../components/mybutton/MyButton";
import Orders from "../../../components/admin/orders/Orders";

// Styles
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import userActions from "../../../redux-toolkit/actions/user/user.action";

// const { Title, Text } = Typography;
function MainPage() {
  const ordersPending = useSelector((state) => state.userReducer.ordersPending)
  const OrderDelivered = useSelector((state) => state.userReducer.OrderDelivered)
  const dispatch = useDispatch()
  const { token } = useToken()

  useEffect(() => {
    if (!ordersPending && !OrderDelivered) {
      dispatch(userActions.getAdminOrders(token, "PENDING"))
      dispatch(userActions.getAdminOrders(token, "COMPLETED"))

    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(userActions.getAdminOrders(token, "PENDING"))
      dispatch(userActions.getAdminOrders(token, "COMPLETED"))
    }, 60000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])


  return (
    <Layout title="Admin Dashboard">
      <div className="main-page">
        <div style={{ marginTop: "20px" }}>
          <Link
            className="my-link"
            to="/create-new-user"
            style={{ background: "#609BE5", color: "#fff" }}
          >
            Create User
          </Link>
          <Link
            className="my-link"
            to="/create-order"
            style={{ background: "rgb(251, 146, 11)", color: "#fff", marginLeft: '5px' }}
          >
            Create Order
          </Link>
        </div>
        <Row gutter={16}>
          <Col className="gutter-row" xl={12} lg={24} md={24} sm={24}>
            <Orders heading="Orders To Deliver" showFooter={false} item={ordersPending?.orders} />
          </Col>
          <Col className="gutter-row" xl={12} lg={24} md={24} sm={24} >
            <Orders
              heading="Delivered Orders"
              text={`View All (${OrderDelivered?.length})`}
              showFooter={OrderDelivered?.length > 0}
              item={OrderDelivered?.orders}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default MainPage;
