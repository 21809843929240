import _PrivateRoutes from './PrivateRoutes';
import _PublicRoute from './PublicRoute';
import _ShareRoute from './shareRoute';
import _UserRoute from './UserRoutes';
import _SubUserRoute from './UserRoutes';
import _CheckPaymentRoute from './checkPaymentRoute';

export const PrivateRoutes = _PrivateRoutes;
export const PublicRoute = _PublicRoute;
export const ShareRoute = _ShareRoute;
export const UserRoute = _UserRoute;
export const SubUserRoute = _SubUserRoute;
export const CheckPaymentRoute = _CheckPaymentRoute